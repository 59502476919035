.stage-gallery {
   position: relative;
   overflow:hidden;
   
   .listItems {
      display: flex;
      flex-wrap:wrap;
      align-items:flex-start;
      justify-content:flex-start;
      margin:0 -10px 1px;
      
      .item-holder {
         display:flex;
         width:19.2%;
         padding: 0 10px;
         flex-direction:column;
         &--lg {
            width:42.4%;
         }
      }
      
      .item {
         text-align: center;
         white-space: normal;
         vertical-align: top;
         position:relative;
         margin:0 0 20px;
         overflow:hidden;
         &.hover,
         &:hover,
         &:focus {
            
            .stage-gallery_text-box {
               top:0;
            }
         }
         &.video {
            
            .stage-gallery_img-holder {
               &:before,
               &:after {
                  display:block;
               }
            }
         }
      }
   }
   
   .cycle-carousel-wrap {
      
      .item {
         opacity: 1 !important;
      }
   }
   
   &_img-holder {
      position:relative;
      width:100%;
      height:100%;
      display:block;
      overflow:hidden;
      &:before,
      &:after {
         display:none;
         content:'';
         position:absolute;
         left:50%;
         top:50%;
         transform:translate(-50%, -50%);
      }
      &:before {
         width: 50px;
         height: 50px;
         opacity: 0.7;
         background-color: white;
         border-radius:100%;
      }
      &:after {
         content: "\e075";
         font-family: icons-default;
         font-size:20px;
         color: #111212;
      }
   }
   
   img {
      width:100%;
      max-width:none;
   }
   
   &_title {
      display:block;
      width:100%;
      position:relative;
      font-size:1.5rem;
      line-height:1.2;
      color: $color-black;
      text-transform:uppercase;
      margin:0 0 18px;
      &:after {
         content:'';
         position:absolute;
         left:50%;
         bottom:-7px;
         width:126px;
         height:1px;
         transform:translateX(-50%);
         background:$color-black;
      }
   }
   
   p {
      margin:0 0 11px;
   }
   
   &_text-box {
      position:absolute;
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      justify-content:center;
      height:100%;
      top:100%;
      left:0;
      right:0;
      padding:10px 2px;
      color:$color-black;
      font:$bold 1.8rem/1.1 $typo-4;
      background:rgba($color-2--2,0.85);
      transition:top 0.3s ease-in-out;
      z-index:2;
      @media only screen and (max-width:1440px) {
         font-size:1.4rem;
      }
      
      .button-1 {
         position:static;
         font-size:1.3rem;
         min-width:141px;
         width:auto;
         line-height:1;
         padding:10px 8px;
         text-transform:uppercase;
         // cursor:default;
         // pointer-events:none;
         background:none !important;
         border-color:#000;
      }
   }
   
   .prevNextCaroussel3 {
      margin: 0;
      
      .prevCaroussel3,
      .nextCaroussel3 {
         position:absolute;
         top:50%;
         left:-13px;
         padding: 0;
         transform:translateY(-50%);
         &:before {
            display: none;
         }
         
         button {
            width: 40px;
            height: 40px;
            position: relative;
            overflow: hidden;
            img {
               display: none;
            }
            &:after {
               position:absolute;
               top:50%;
               left:50%;
               transform: translate(-50%, -50%);
               font-family: icons-default;
               color: $color-2--2;
               font-size: 20px;
               line-height: 1;
               content: '\e016';
            }
         }
      }
      .nextCaroussel3 {
         left: auto;
         right: -13px;
         
         button {
            &:after {
               content: '\e017';
            }
         }
      }
   }
}