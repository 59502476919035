// @name Content list 1
.list-content-1, .rte .list-content-1 {
    $list-content-1__font-size: 1.4;
    font-size: 1em;
    
    > li {
        text-transform: uppercase;
        font-size: #{$list-content-1__font-size}em;
        font-family: $typo-2;
        font-weight: $light;
        margin: em(0.5, $list-content-1__font-size) 0;
        @include default-icons-before('\e027', 0 5px 0 0, em(1.2, $list-content-1__font-size), $color-1--2, initial);
        
        > a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
}

// @name Content list 2
// @description You can see this list on content page .go-so-far.
.list-content-2, .rte .list-content-2 {
    $list-content-2__font-size: 1.6;
    font-size: 1em;
    
    > li {
        font-size: #{$list-content-2__font-size}rem;
        font-family: $typo-5;
        font-weight: $light;
        margin: em(1, $list-content-2__font-size) 0;
        padding-left: 10px;
        @include default-icons-absolute-before('\e027', 9px, $color-2--2, 5px, inherit, inherit, -2px);
        
        > a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-2--2;
            }
        }
    }
}
