.home-news {
    $this: &;

    margin: 10rem 0 7rem;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3rem;
    }

    &__item {
        flex-basis: calc(50% - 4rem);
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 2rem 2rem;

        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
    }

    &__link {
        display: block;

        &:hover,
        &:focus {
            text-decoration: none;

            #{$this}__title {
                text-decoration: underline;
            }
        }
    }

    &__picture {
        display: block;

        img {
            display: block;
            font-family: 'object-fit: cover; object-position: center;';
            height: auto;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;

        &::before {
            background-color: $color-2--2;
            content: '';
            display: block;
            height: 0.1rem;
            left: 0;
            position: absolute;
            top: -1.5rem;
            width: 22rem;
        }
    }

    &__title {
        color: lighten($color-black, 12.94);
        font-family: $typo-4;
        font-size: 2.4rem;
        font-weight: $bold;
        line-height: 2.5rem;
        margin: 4rem 0 2rem;

        span {
            display: block;
            letter-spacing: -0.072rem;
            text-transform: uppercase;
        }
    }

    &__teaser {
        color: $color-black;
        font-family: $typo-4;
        font-size: 1.5rem;
        font-weight: $light;
        line-height: 1.9rem;

        span {
            text-transform: uppercase;
        }
    }
}
