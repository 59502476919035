.language {
    display: none;
}

.gtranslate {
    &-select {
        margin: 0.5rem auto 0;
    }
}

.pane-gtranslate-gtranslate {
    height: 8.8rem;
    padding: 2rem 2.5rem;

    .pane-title {
        color: $color-black;
        text-align: center;
    }
}
