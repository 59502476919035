.home_text-section_col-holder {
	display: block;
}
.home_text-section_text-col {
	width: 100%;
	&:first-of-type {
		margin-bottom: 20px;
	}
    
    ul {
        margin-left: 0;
        li {
            padding-left: 0;
            padding-right:11px;
            margin-bottom: 15px;
            &:before {
                display: block;
                float: left;
                position: static;
                margin-top: 5px;
                margin-right: 2px;
            }
        }
    }
}
.home_text-section_item {
    display: block;
    text-align: center;
	margin-bottom: 30px;
	&-wrap {
			margin: 0 0 6px;
	}
}
.home_text-section_item_text {
    display: block;
}
.home_text-section_text-box {
    padding: 0 10px 0 70px;
}

.home_text-section_text-block {
    
    legend {
        margin:0 0 16px;
    }
    
    .form__field-wrapper {
        width:100%;
        margin:0 0 32px;
    }
    
    .button-2 {
        display:block;
        width:241px;
        margin:0 auto 25px;
        padding:22px 42px 12px;
    }
}

.home_text-section_form-list {
    text-align:center;
    
    li {
        margin:0 6px 10px;
    }
}