.home-section {
    background: $color-white;
    //font-size: 1.4rem;
    //line-height: 1.215;
    //overflow: hidden;

    .home-section_lg-button {
        margin-bottom: 7.8rem;
    }

    &.angle-section {
        background: $color-3--1;
        overflow: visible;
        @include angle(both, false, 1deg);

        &:before,
        &:after {
            background: $color-3--1;
        }

        .section-main__wrapper {
            padding-top: 3rem;
        }
    }

    h2 {
        color: $color-1--2;
        font-size: 3rem;
        font-family: $typo-4;
        font-weight: $bold;
        letter-spacing: -0.09rem;
        line-height: 1;
        margin: 0 0 2rem 0;
        text-transform: uppercase;

        span {
            display: inline-block;
            position: relative;
            vertical-align: top;

            //&:before {
            //    background: $color-1--2;
            //    content: '';
            //    height: 0.1rem;
            //    left: 0;
            //    position: absolute;
            //    top: -1rem;
            //    width: 4.6rem;
            //}
        }
    }

    .home-section_title {
        max-width: 40rem;
    }

    .section-main__wrapper {
        padding-top: 4.3rem;
    }
}

.home-section_heading {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0 0 3rem;
    position: relative;
}

.home-section_lg-button {
    background-color: $color-3--1;
    color: $color-1--2;
    display: block;
    font-family: $typo-4;
    font-size: 1.4rem;
    font-weight: $bold;
    height: 4.2rem;
    line-height: 1;
    padding: 1.3rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    @include default-icons-before('\e027', 0 0.7rem 0 0, 1.3rem, $color-1--2, -0.8rem);
    //@include default-icons-absolute-before('\e027', 1.3rem, $color-1--2, 50%, inherit, inherit, 0);

    &::before {
        transform: translateY(-50%);
    }

    &:hover,
    &:focus {
        background-color: $color-3--1 - 20;
        text-decoration: none;
    }
}

.home-section_lg-button--2 {
    font-size: 1.2rem;

    span {
        &:before {
            content: "\e05f";
            left: -18px;
        }
    }
}

.home-section_lg-button--white {
    background: $color-white;

    &:hover,
    &:focus {
        background: $color-1--2;
    }
}
