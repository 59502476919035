.stage-gallery {
   overflow:visible;
   
   .listItems {
      margin:0 15px;
      
      .item-holder {
         display:block;
         width:100%;
      }
      
      .item {
         width:100%;
         margin:0;
      }
   }
   
   img {
      display:block;
      width:100%;
      max-width:none;
   }
   
   &_text-box {
      position:static;
      background:none;
      font-size:1.8rem;
      padding:16px 10px;
      color: #fff;
      
      .button-1 {
         color: #8dc63f;
         border-color: #8dc63f;
         &:hover,
         &:focus {
            background:$color-white !important;
            border-color:$color-white;
         }
         &:after {
            display:none;
         }
      }
   }
   
   p {
      margin:0 0 30px;
   }
   
   .prevNextCaroussel3 {
      position:absolute;
      left:0;
      right:0;
      top:50%;
      margin-top:-60px;
      
      .prevCaroussel3,
      .nextCaroussel3 {
         top:50%;
         left:-25px;
         margin-top:-20px;
      }
      .nextCaroussel3 {
         left: auto;
         right: -25px;
      }
   }
}

.stage-gallery_title {
   color: #fff;
   &:after {
      background: #8dc63f;
   }
}