.ddm.ddm__content {
    margin: 3em 0 5em 0;
    
    &.ddm--active {
        
        .ddm__sub-level {
            padding:26px 10px 13px 30px;
        }
    }
}

.ddm__content {
    
    .ddm__button {
        font-size: 1.4rem;
        padding: 13px 55px 13px 10px;
    }
    
    h4 {
        font-size:1.6rem;
        line-height:1.6;
        margin:0 0 11px;
    }
    
    h5 {
        font-size:1.5rem;
        line-height:1.3;
        margin:0 0 18px;
    }
    
    p {
        margin:0 0 18px;
    }
    
    ul,
    ol {
        padding:0;
        margin:0 0 -8px;
        font-size:1.4rem;
        
        > li {
            margin:0 0 8px;
            padding-left:7px;
        }
        
        li {
            &:before {
                left:-6px;
            }
        }
    }
}