// @name Title 1
// @description Styling for title 1

$title--1__font-size: 2.5;
.title-1 {
    font-size: #{$title--1__font-size}rem;
    font-weight:$bold;
}

.title-2 {
    margin-bottom:17px;
}