.home-events {
    margin: 0 0 6rem;

    &__wrap {
        justify-content: center;
        margin: 0;
    }

    &__item {
        margin: 0 0 5.5rem;
        max-width: 34rem;
        width: 100%;

        &-wrap {
            margin-right: 0.5rem;
        }
    }

    &__category {
        left: -1rem;
    }

    &__title {
        font-size: 1.8rem;
    }

    .home-section_lg-button {
        max-width: 34rem;
        width: 100%;
    }

    &__data {
        width: 10rem;
    }
}
