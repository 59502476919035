.home-events {
    $this: &;

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-bottom: 6rem;

        &:last-child {
            margin-bottom: 4.7rem;
        }

        &-wrap {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 1rem;
            max-width: 20rem;
            position: relative;
        }
    }

    &__link {
        display: flex;

        &:hover,
        &:focus {
            text-decoration: none;

            #{$this}__title {
                text-decoration: underline;
            }
        }
    }

    &__picture {
        display: block;
        position: relative;

        @at-root {
            div#{&} {
                min-width: 20rem;
                @include default-icons-absolute-before('\e04b', 5rem, $color-3--2, 50%, inherit, inherit, 50%);

                &::before {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition: opacity ease 0.3s;
                }

                &::after {
                    background: $color-3--1;
                    content: '';
                    display: block;
                    //padding-top: 66.66667%;
                    padding-top: 67.333334%;
                }
            }
        }

        img {
            height: 100%;
            width: 100%;
            font-family: 'object-fit: cover; object-position: center;';
            object-fit: cover;
        }
    }

    &__category {
        background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%2341044f' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 103 39' width='103' height='39'%3E%3Cpath d='M9 0.16L103 0.16L103 39L-0.69 31.22L9 0.16Z' id='a1rBMBEnR'%3E%3C/path%3E%3C/svg%3E");
        background-size: 100% 100%;
        color: $color-white;
        font-family: $typo-4;
        font-size: 1.3rem;
        font-weight: $bold;
        left: -2.1rem;
        letter-spacing: -0.039rem;
        line-height: 1;
        min-height: 3.9rem;
        min-width: 10.3rem;
        padding: 1.1rem 1.5rem 1.5rem 4.7rem;
        position: absolute;
        text-transform: uppercase;
        top: 1.5rem;
    }

    &__title {
        color: lighten($color-black, 12.94);
        font-family: $typo-4;
        font-size: 2rem;
        font-weight: $bold;
        line-height: 1;
        margin-top: 1.4rem;
        letter-spacing: -0.06rem;
        position: relative;

        &::after {
            background-color: $color-1--2;
            content: '';
            display: block;
            height: 0.1rem;
            left: 0;
            position: absolute;
            bottom: -1.5rem;
            width: 100%;
        }
    }

    &__data {
        //background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" fill="#{$color-2--2}" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 124 71" width="124" height="71"><path d="M0 0L125 0L114.26 71.63L0 61.48L0 0Z" id="aIUqZoTnY"></path></svg>');
        background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill-opacity='0.95' fill='%238dc63f' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 124 71' width='124' height='71'%3E%3Cpath d='M0 0L125 0L114.26 71.63L0 61.48L0 0Z' id='aIUqZoTnY'%3E%3C/path%3E%3C/svg%3E");
        background-size: 100% 100%;
        display: flex;
        height: 7.1rem;
        justify-content: center;
        padding: 1.2rem;
        width: 12.4rem;

        time {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 1rem;
            position: relative;
            width: 100%;

            & + span + time {
                @include default-icons-absolute-before('\e017', 2rem, $color-black, 50%, inherit, inherit, -1.8rem);
                &:before {
                    font-weight: $bold;
                    transform: translateY(-50%);
                }
            }
        }

        .day,
        .month {
            color: $color-black;
            font-family: $typo-4;
            font-size: 1.6rem;
            font-weight: $bold;
            text-transform: uppercase;

        }

        .day {
            font-size: 2.8rem;
            letter-spacing: -0.084rem;
            line-height: 2.4rem;
        }

        .month {
            letter-spacing: -0.048rem;
            line-height: 1.7rem;
        }
    }

    &__hour-place {
        text-align: center;
        margin-top: 1rem;

        .hour {
            display: block;
            color: $color-black;
            font-family: $typo-4;
            font-size: 1.5rem;
            line-height: 1.9rem;
            text-transform: uppercase;
            font-weight: $light;
            margin-bottom: 0;
        }

        .place,
        .tarif {
            display: block;
            color: lighten($color-black, 10.98);
            font-family: $typo-4;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: $light;
            text-transform: none;

            span {
                text-transform: uppercase;
            }
        }
    }

    .home-section_lg-button {
        margin-left: auto;
        width: 100%;
    }
}
