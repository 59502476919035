// @name Breadcrumb
.breadcrumb {
    $breadcrumb__font-size: 1.4;
    font-family: $typo-5;
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    p {
        font-size: #{$breadcrumb__font-size}rem;
        font-weight: $normal;
        color: $color-2--2;
        .breadcrumb__here {
            color: $color-3--3;
        }
        a {
            font-weight: $normal;
            color: $color-white;
            @include default-icons-after('\e027', 0 1px 0 5px, em(1.2, $breadcrumb__font-size), $color-white, 0);
            &:last-child {
                &:after {
                    color: $color-2--2;
                }
            }
            &:hover,
            &:focus {
                text-decoration:none;
                color:$color-2--2;
            }
        }
    }
}
