.plans {
	.section-main__wrapper {
	    padding-top: 4.6em;
	}
	&-carousel {
		width: 100%;
		float: none;
		margin: 0 0 20px;
	}
	h2 {
		font-size: 1.6rem;
	}
	.slider-galerie__item  {
		width: 100%;
	}
	.carousel-galerie__item {
		width: 32.9%;
		button {
			width: 100%;
		}
	}
	.carousel-galerie__pager {
		display: block !important;
	}
	&-info {
		display: block;
		&-row {
			display: block;
			margin: 0 0 17px;
			> div {
				display: block;
				padding: 0 10px 0 0;
			}
		}
		&-title {
			width: 100%;
		}
	}
	.button-2 {
		width: 100%;
		text-align: center;
	}
	&-text {
		p {
			margin: 0 0 30px;
		}
	}
}