.home-section_partners {
    
    .section-main__wrapper {
        padding-top:20px;
        padding-bottom:20px;
    }
    
    .home-section_heading {
        margin-bottom: -20px;
    }
    
    .section-main__wrapper {
        & + .section-main__wrapper {
            padding-top: 0;
            padding-bottom: 3em;
        }
    }
    
    .home-section_heading {
        margin-bottom: -10px;
    }
}

.partners-gallery {
    padding: 0 20px 23px;
    position: relative;
    width: 100%;
    
    .listItems {
        display: flex;
        margin: 0 -11px;
        
        .item {
            padding: 0 9px;
            text-align: center;
            white-space: normal;
            vertical-align: top;
            a {
                display: block;
                text-decoration: none;
                &:hover,
                &:focus {
                    outline: none;
                    .partners-gallery {
                        
                        &-title {
                            color:$color-white;
                            background:$color-2--2;
                        }
                    }
                }
            }
        }
    }
    
    &_img {
        position: relative;
        margin: 0 0 15px;
        overflow: hidden;
        
        img {
            display: block;
            margin:0 auto;
        }
    }
    
    .cycle-carousel-wrap {
        .item {
            opacity: 1 !important;
        }
    }
    
    &-title {
        padding:15px 20px;
        font: $normal 1.3rem/1.2 $typo-5;
        color: $color-black;
        text-align: center;
        transition:all 0.3s ease;
        background:$color-white;
    }
    
    .prevNextCaroussel3 {
        margin: 0;
        .prevCaroussel3,
        .nextCaroussel3 {
            position: absolute;
            top: 50%;
            left: -13px;
            padding: 0;
            transform: translateY(-50%);
            margin-top: -23px;
            &:before {
                display: none;
            }
            button {
                width: 40px;
                height: 40px;
                position: relative;
                overflow: hidden;
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: icons-default;
                    color: #ccc;
                    font-size: 20px;
                    line-height: 1;
                    content: '\e016';
                }
                img {
                    display: none;
                }
            }
        }
        .nextCaroussel3 {
            left: auto;
            right: -13px;
            button {
                &:after {
                    content: '\e017';
                }
            }
        }
    }
}