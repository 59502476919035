.home_gallery-section {
    margin: 0 0 11rem;

    &_items {
        height: 18.3rem;
    }

    &_image {
        @at-root {
            div#{&} {
                &::after {
                    padding-top: 26%;

                    @media (max-width: 700px) {
                        padding-top: 29%;
                    }

                    @media (max-width: 600px) {
                        padding-top: 34%;
                    }

                    @media (max-width: 500px) {
                        padding-top: 41%;
                    }

                    @media (max-width: 460px) {
                        padding-top: 45%;
                    }

                    @media (max-width: 400px) {
                        padding-top: 52%;
                    }

                    @media (max-width: 320px) {
                        padding-top: 67%;
                    }
                }
            }
        }
    }

    &_container {
        width: 25.3rem;
        min-height: 12.1rem;
        right: 0.9rem;
        bottom: -3.8rem;
        padding: 4.9rem 5.6rem 2.6rem 3.2rem;

    }

    &_category {
        left: -2.9rem;
    }

    &_title {
        font-size: 2rem;
    }
}

.carrousel1 {
    .prevNextCarrousel1 {
        .prevCarrousel1,
        .nextCarrousel1 {
            top: 44%;
            left: 1.5rem;
        }

        .nextCarrousel1 {
            left: auto;
            right: 1.5rem;
        }
    }
}
