// @name Title 1
// @description Styling for title 1
$title--1__font-size: 3;
.title-1 {
    position:relative;
    font-family: $typo-4;
    font-size: #{$title--1__font-size}rem;
    line-height: 3.6rem;
    font-weight: $bold;
    color:$color-1--2;
    text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
    &:before {
        content:'';
        position:absolute;
        left:0;
        top:-12px;
        width:46px;
        height:1px;
        background:$color-1--2;
    }
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    position:relative;
    font-family: $typo-4;
    font-size: #{$title--2__font-size}rem;
    font-weight: $bold;
    text-transform: uppercase;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    position:relative;
    font-family: $typo-4;
    font-size: #{$title--3__font-size}rem;
    line-height: 1em;
    font-weight: $bold;
    text-transform: uppercase;
    color: $color-1--2;
    margin: em(2.5, $title--3__font-size) 0 22px;
    &:before {
        content:'';
        position:absolute;
        left:0;
        top:-12px;
        width:46px;
        height:1px;
        background:$color-1--2;
    }
}


// .column-bloc_title {
//     padding: 0 5px 9px;
//     margin: 0 0 38px;
// }