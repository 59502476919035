// import google font
@if variable-exists(import-google-font-1) {
    @import url($import-google-font-1);
}

@if variable-exists(import-google-font-2) {
    @import url($import-google-font-2);
}


@if variable-exists(import-google-font-3) {
    @import url($import-google-font-3);
}

@if variable-exists(import-google-font-4) {
    @import url($import-google-font-4);
}

@if variable-exists(import-google-font-5) {
    @import url($import-google-font-5);
}
// Icon fonts
// create font-face for icon fonts
@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
@include fontFace(icons-project, 'icons', normal, normal);

// @name Body
body {
    font-family: $typo-1;
    font-weight: $normal;
    overflow-x: hidden;
    min-width:320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// hide SVG symbol
.svg-symbol {
    display: none;
}
