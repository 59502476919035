
// @name Content list 2
// @description You can see this list on content page .go-so-far.
.list-content-2, .rte .list-content-2 {
    $list-content-2__font-size: 1.6;
    margin-bottom:-8px;
    
    > li {
        font-size: #{$list-content-2__font-size}rem;
        margin:0 0 8px;
        padding-left: 10px;
        // &:before {
        //     top: 3px;
        //     left: -2px;
        // }
    }
}
