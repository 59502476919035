// # Global
$domain: "stratis.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i";
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";
$import-google-font-3: "https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700";
$import-google-font-4: "https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700,700i";
$import-google-font-5: "https://fonts.googleapis.com/css?family=Ubuntu+Condensed";



$typo-1: 'Open Sans', sans-serif;
$typo-2: 'Open Sans Condensed', sans-serif;
$typo-3: 'Oswald', sans-serif;
$typo-4: 'Quicksand', sans-serif;
$typo-5: 'Roboto', sans-serif;
$typo-6: 'Ubuntu Condensed', sans-serif;



$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;


// ## Path
$image-bg-url: "../Images/bg/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";


// ## Media queries
$smartphone: 767;
$tablet: 1279;


// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;
$red: #ca000a;


// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;


// ## color-1
$color-1--1: #95ca4c;
$color-1--2: #41044f;
$color-1--3: #26012e;
$color-1--4: #2d0137;

// ## color-2
$color-2--1: #aecf80;
$color-2--2: #8dc63f;
$color-2--3: #3b462c;
$color-2--4: #8ec043;

// ## Color-3
$color-3--1: #f1f1f1;
$color-3--2: #c5c5c5;
$color-3--3: #727272;
$color-3--4: #ccc;
$color-3--5: #3c3c3b;
$color-3--6: #458ecd;
$color-3--7: #505456;

// Block contact color
$bloc-contact-color: #93c949;
