// @name Document list
// @description List of document in publications list


.list-document__listitems {
	margin: 0;
    .list-document__item {
          display: block;
    width: 100%;
    margin: 0 0 10px;
    padding-left: 0;
    padding-right: 0;
     &.list-document__item--postulate {
     	width: 100%;
     }
    }

}
