.header-logo {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1.3rem;

    .logo-wrap {
        display: flex;
        text-decoration: none;
    }

    .logo-left {
        color: $color-white;
        font-family: $typo-4;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: -0.048rem;
        font-weight: $bold;
        text-align: right;
        text-transform: uppercase;
        margin-right: 3.6rem;

        strong {
            font-size: 2.8rem;
            letter-spacing: -0.084rem;

            span {
                font-size: 2.2rem;
                letter-spacing: -0.066rem;
            }
        }

        span {
            font-size: 1.2rem;
        }

        i {
            font-weight: $medium;
            text-transform: none;
        }
    }

    .link-logo {
        display: block;

        img {
            display: block;
            width: 16.6rem;
            height: 5.2rem;
        }
    }
}
