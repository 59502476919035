// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-kiosk {
    
    .column-bloc__wrapper {
        width:100%;
        text-align:center;
        margin:0 0 31px;
    }
    
    .bloc-kiosk__item {
        display:block;
    }
    
    .bloc-kiosk__picture {
        display:block;
        width:100%;
        text-align:center;
        margin:47px 0 17px;
        
        img {
            max-width:100%;
            width:auto;
        }
    }
    
    .link-view {
        width:100%;
        text-align:center;
    }
}

