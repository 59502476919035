.ddm.ddm__location {
  margin:44px 0 17px;
    
    .ddm__button {
        font-size:2.5rem;
        margin:0 0 45px;
        padding:0;
    }
}

.location-block {
  margin:0;
}

.location-block_info-box {
  padding:27px 16px 36px;
  
  .button {
    padding:13px 24px;
    width:100%;
    text-align:center;
  }
}

.location-block_wrapper {
  padding:0 4px;
  
  > * {
    margin:0 0 12px;
  }
  
  p {
    font-size:1.3rem;
    margin:0 0 10px;
  }
}