// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
   margin:44px -10px 85px;
   width: auto;
   max-width: none;
   position: relative;
   overflow: visible;
   .carousel-galerie__thumb {
      display: none;
   }
   .infos-img__wrap {
      max-width: none;
      padding: 0;
   }
}

.slider-galerie {
   margin: 0;
   .infos-img {
      display: block;
      width: 100%;
      height: 60px;
      margin: 0;
      padding: 6px 10px;
      text-align: left;
      font-size: 1.2rem;
      .infos-img__wrap {
         display: inline-block;
         vertical-align: top;
         padding-right: 14px;
         span {
            display: block;
         }
      }
   }
   a {
      padding: 68% 0 0 0;
   }
}

.carousel-galerie {
   position: static;
   display: block;
}

.carousel-galerie__pager {
   position: static;
   width: auto;
   height: auto;
   margin: 16px 0 10px 23px;
}

.carousel-galerie__prev,
.carousel-galerie__next {
   width: 24px;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   z-index: 2;
   transform: translate(0, 0);
   margin: 0 !important;
   padding: 0!important;
   button {
      border: none;
      width: 100%;
      height: 100%;
      background: rgba($color-2--2, 0.5);
      &:focus {
         outline-color: #fff;
      }
      &:before {
         color: #fff;
         font-weight: 400;
         content: '\e075';
         font-size: 2rem;
      }
   }
   &:hover,
   &:active {
      opacity: 1;
   }
}

.carousel-galerie__prev {
   button {
      &:before {
         transform: translate(-50%, -50%) rotate(-180deg);
      }
   }
}

.carousel-galerie__next {
   left: auto;
   right: 0;
   button {
      &:before {
         content: '\e075';
      }
   }
}
