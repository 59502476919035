// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    
    .bloc-contact__wrapper {
        display:flex;
    }
    
    .bloc-contact_holder {
        width:50%;
        text-align:center;
    }
    
    .bloc-contact_list {
      max-width:60%;
      margin:0 auto;
    }
    
    .list-infos {
      margin:0 0 5px;
    }
    
    .list-infos__item {
      display:inline-block;
    }
    
    .bloc-contact__wrapper {
        padding:19px 20px 24px;
        &:before {
            left: -13px;
            top: -8px;
            right: 4px;
            bottom: 5px;
            transform: rotate(0.8deg) skew(-4deg, 0deg);
        }
    }
}