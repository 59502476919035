.home-news {
    margin: 9.5rem 0 6rem;

    &__wrap {
        margin: 0 -1rem;
    }

    &__item {
        flex-basis: calc(50% - 2rem);
        margin: 0 1rem 1rem;
    }
}
