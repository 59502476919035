// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size: 3.2;
    font-size: #{$title--1__font-size}em;
    margin: em(2.5, $title--1__font-size) 0 em(1.5, $title--1__font-size);
}

.title-2 {
    margin-bottom: 15px;
}

.title-3 {
	margin-left:45px;
	&:before {
		top:-14px;
	}
}