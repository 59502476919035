.home-section_heading {
    display: block;
    margin: 0 0 2rem;
}

.home-section {

    .section-main__wrapper {
        padding-top: 2.8em;
    }

    //h2,
    //.home-section_title {
    //    font-size: 2.8rem;
    //    margin: 0 0 18px;
    //    padding-bottom: 0;
    //}

    .home-section_lg-button--2 {
        margin-bottom: 0;
    }
}

.home-section_lg-button {

    span {
        &:before {
            left: -20px;
        }
    }
}
