// @name Pager list
.pager {
    li {
        &.pager__prev,
        &.pager__next {
            span,
            a {
                display: inline-block;
                margin-top: -3px;
                width: 40px;
                height: 40px;
                text-indent: -9999px;
                white-space: nowrap;
                position: relative;
                padding: em(1, 1.4) em(1.8, 1.4);
                &:before,
                &:after {
                    text-indent: 0;
                    line-height: 0;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
            }
        }
    }
    &-single {
        &__back-to-list {
            max-width: 200px;
          margin-top: -3px !important;
        }
    }
}
