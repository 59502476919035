.scenes-gallery {
   padding: 0 75px 70px 45px;
   position: relative;
   width: 100%;
   
   .listItems {
      display: flex;
      justify-content: center;
      
      .item {
         padding: 0 15px;
         text-align: center;
         white-space: normal;
         vertical-align: top;
         a {
            display: block;
            max-width: 144px;
            margin: 0 auto;
            text-decoration: none;
            &:hover,
            &:focus {
               
               .scenes-gallery {
                  
                  &-title {
                     color: $color-1--2;
                  }
               }
            }
         }
      }
   }
   
   .cycle-carousel-wrap {
      
      .item {
         opacity: 1 !important;
      }
   }
   
   &-ico {
      border-bottom:1px solid #ccc;
      padding:3px 0 14px;
      margin:0 0 14px;
      height:82px;
      display:flex;
      align-items:center;
      justify-content:center;
      
      svg {
         fill: $color-1--2;
         * {
            fill: $color-1--2;
         }
      }
   }
   
   .gallery-ico-2 {
      width:58px;
      height:56px;
   }
   
   .gallery-ico-3 {
      width:50px;
      height:58px;
   }
   
   .gallery-ico-4 {
      width:49px;
      height:55px;
   }
   
   &-title {
      font:$bold 1.4rem/1.2 $typo-4;
      color: $color-black;
      text-transform:uppercase;
      transition: all 0.2s linear;
   }
   
   .prevNextCaroussel3 {
      margin: 0;
      
      .prevCaroussel3,
      .nextCaroussel3 {
         position:absolute;
         top:28px;
         left:-20px;
         padding: 0;
         &:before {
            display: none;
         }
         &:hover,
         &:focus {
            
         }
         
         button {
            width: 40px;
            height: 40px;
            position: relative;
            overflow: hidden;
            img {
               display: none;
            }
            &:after {
               position:absolute;
               top:50%;
               left:50%;
               transform: translate(-50%, -50%);
               font-family: icons-default;
               color: #ccc;
               font-size: 20px;
               line-height: 1;
               content: '\e016';
            }
         }
      }
      .nextCaroussel3 {
         left: auto;
         right: 7px;
         
         button {
            &:after {
               content: '\e017';
            }
         }
      }
   }
}
