.annonces-list {
    .list-type-1__picture {
        background: #fff;
        margin-right: 20px;
        img {
            max-width: none;
            margin: 0;
            transition: all 0.3s linear;
        }
    }
    .list-type-5__title {
        font-size: 2.4rem;
        font-weight: 700;
        font-family: $typo-4;
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .list-type-1__item {
    &:hover,
    &:focus {
        .list-type-1__picture {
            img {
                opacity: 0.8;
            }
        }
       .list-type-5__title {
        a {
            text-decoration: underline;
        }
       }
    }
    }
    span {
        display: block;
    }
    .list-type-5__date {
        text-align: right;
        font-size: 1.3rem;
    }
}