.home_gallery-section {
    $this: &;

    margin: 0 0 9.5rem;

    &_items {
        height: 44.5rem;
    }

    &_image {
        @at-root {
            div#{&} {
                &::after {
                    padding-top: 38%;

                    @media (max-width: 1160px) {
                        padding-top: 40%;
                    }

                    @media (max-width: 1100px) {
                        padding-top: 43%;
                    }

                    @media (max-width: 1000px) {
                        padding-top: 47%;
                    }

                    @media (max-width: 900px) {
                        padding-top: 53%;
                    }

                    @media (max-width: 800px) {
                        padding-top: 60%;
                    }
                }
            }
        }
    }

    &_container {
        width: 27.4rem;
        min-height: 13.1rem;
        right: 1rem;
        padding: 5.3rem 2.4rem 2.9rem 5.3rem;

    }

    &_title {
        font-size: 2.4rem;
    }
}
