.menu-cross {
    width: 58%;
    float: left;
    padding: 0;
    &:before {
        display: none;
    }
    ul {
        min-width: none;
        width: 50%;
        &:first-of-type {
            margin-right: 22px;
            padding-right: 6px;
        }
    }
}
