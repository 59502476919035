.home_stage-games-section {
   background:$color-1--2 !important;
   padding: 23px 0 0;
   
   .section-main__wrapper--2 {
      max-width:166rem;
      padding:0 30px;
   }
   
   .wrapper-1220 {
      padding-bottom:0;
      
      & + .wrapper-1220 {
         padding-top:0;
      }
      
      &:last-child {
         padding-top:0;
         padding-bottom:3em;
      }
   }
   
   h2 {
      color:$color-white !important;
      
      span {
         &:before {
            background:$color-white !important;
         }
      }
   }
   
   .home-section_heading {
      
      .stage-games_btn-more {
         color:$color-2--2;
         border-color:$color-2--2;
         &:hover,
         &:focus {
            border-color:$color-white;
            color:$color-white;
         }
      }
   }
   
   .stage-games_btn-more {
      background:none;
      height:auto;
      align-self:flex-start;
      font-size:1.4rem;
      color:$color-black;
      border-color:$color-black;
      &:hover,
      &:focus {
         border-color:$color-black;
         color:$color-black;
      }
   }
   
   .home-section_lg-button {
      margin-bottom: 25px;
      color:$color-white;
      border-color: #24002c;
      background-color: #24002c;
      &:hover,
      &:focus {
         background-color: #24002c - 20;
      }
      
      span {
         &:before {
            color:#fff;
         }
      }
   }
}

