.language {
    color: #525252;
    font-family: $typo-4;
    font-size: 1.4rem;
    line-height: 1.2;
    padding-top: 15px;
    text-transform: uppercase;
    li {
        float: left;
        position: relative;
        &:not(:first-child) {
            padding-left: 1px;
            &:after {
                background: #ccc;
                content: '';
                height: 97px;
                left: 0;
                position: absolute;
                top: -50px;
                width: 1px;
            }
        }
        &.active {
            a {
                color: $color-1--2;
                font-weight: 700;
            }
        }
    }
    a {
        color: $color-1--2;
        display: block;
        padding: 10px;
    }
}

.pane-gtranslate {
    &-gtranslate {
        margin-left: 2.5rem;

        .pane-title {
            color: $color-white;
            font-family: $typo-4;
            font-size: 1.5rem;
            font-weight: $bold;
            line-height: 1.4rem;
            letter-spacing: -0.045rem;
        }
    }

}

.gtranslate {
    &-select {
        background-color: $color-white;
        background-position: -6.5rem 0;
        height: 2.4rem;
        margin-top: 1.1rem;
        position: relative;
        width: 18.8rem;

        &:before {
            background: #e8e8e8;
            bottom: 4px;
            content: '';
            position: absolute;
            right: 17px;
            top: 4px;
            width: 1px;
        }

        &:after {
            background-image: url(https://translate.googleapis.com/translate_static/img/te_ctrl3.gif);
            background-position: -65px 0;
            content: '';
            height: 19px;
            left: 5px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 19px;
        }

        select {
            border-bottom: 1px solid #e8e8e8;
            border-left: 1px solid #d5d5d5;
            border-right: 1px solid #d5d5d5;
            border-top: 1px solid #9b9b9b;
            color: #000;
            cursor: pointer;
            font-family: arial;
            font-size: 11px;
            height: 24px;
            padding: 0 0 0 26px;
            white-space: nowrap;
            z-index: 1;
            zoom: 1;
        }
    }
}
