// @name Pager list
.pager {
    width: 100%;
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        font-weight: 700;
        font-family: $typo-4;
        a, span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            padding: 4px;
            line-height: 1em;
        }
        a {
            color: $color-1--2;
           background: #fff;
                border: 1px solid #8dc63f;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                background: $color-1--3;
                border-color: $color-1--3;
                color: $color-white;
            }
        }
        span {
            cursor: default;
            color: #fff;
             background: $color-1--2;
            
        }
        &.pager__prev, &.pager__next {
            margin:-3px 0 0;
            a, span {
                width: auto;
                height: 40px;
                padding: em(1, 1.4) em(2.4, 1.4);

            }
            // Inactive
            span {
                color: $color-1--2;
                background: #fff;
                border: 1px solid #8dc63f;
            }
        }
        &.pager__prev {
            float: left;
            a, span {
                float: left;

                @include default-icons-before('\e026', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), $color-2--2, em( -0.1, 1.4));
               
            }
            span {
                color: $color-1--2;
                // Inactive
                &:before, &:hover:before, &:focus:before {
                    color: #8dc63f;
                }
            }
        }
        &.pager__next {
            float: right;
            a, span {
                float: right;
                @include default-icons-after('\e027', 0 0 0 em(.5, 1.4), em(1.2, 1.4), $color-2--2, em(-.1, 1.4));
            }
            span {
                color: #000;
                // Inactive
                &:after, &:hover:after, &:focus:after {
                    color: #8dc63f;
                }
            }
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-2
    .pager-infinite__button {
        &:before {
            content: "\e05f";
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
         
        a {
            width: auto;
            height: 40px;
            background: $color-1--2;
            color: #fff;
            font-weight: $bold;
            padding: em(1, 1.4) em(2.4, 1.4);
            border-color: $color-1--2;
            @include default-icons-before('\e02f', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), $color-2--2, em(-.2, 1.4));
          
         
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
    &__nav {
        padding: 35px 8px;
        background: #f6f6f6;
    }
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $bold;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &.pager-alphabet__item--current {
            > a {
                background: $color-1--2;
                color: $color-white;
                border-color: $color-1--2;

            }
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            padding: 4px;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            background: #fff;
            border: 1px solid $color-2--2;
            color: $color-black;
            font-family: $typo-4;
            font-weight: 700;
        }
        > a {
            &:hover, &:focus {
                background: $color-1--3;
                border-color: $color-1--3;
                text-decoration: none;
                color: $color-white;
            }
        }

        &:last-child a,
        &:nth-last-child(2) a {
            width: 65px;
        }
    }
}

