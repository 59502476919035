// @name Main section heading
// @description Default css
.heading {
    padding:25px 0 34px;
    
    .heading__wrapper {
        padding: 0 10px;
        display: block;
    }
    .heading__figure {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 2em;
    }
    h1 {
        font-size: 2.4em;
        line-height:1.2;
        margin:0 0 21px;
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__figure {
            margin-left: 0;
        }
    }
    
    .subtitle {
        font-size:1.4rem;
        
        > span {
            min-width:126px;
            padding:8px 20px;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: block;
        vertical-align: inherit;
    }
    .heading__picture {
        img {
            width: 100%;
        }
    }
    .heading__wrapper-date {
        width: 100%;
        border-right: 0;
        display: flex;
        border-bottom: 1px solid $color-3--3;
        margin-bottom: 2em;
         .hour-place {
           flex: auto;
        }
        .date-1 {
            text-align: left;
            width: 124px;
            time:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
    .heading__wrap {
        padding-left: 0;
    }
}
