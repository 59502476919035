.site-infos {
    align-items: self-start;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: auto;
    order: 3;

    &_content {
        p {
            display: block;
        }
    }
}
