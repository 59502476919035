.flash-info {
	background: $color-2--2;
	padding-top: 30px;
	padding-bottom: 36px;
	&-wrap {
		max-width: 960px;
		padding: 0 10px;
		margin: 0 auto;
		display: flex;
	}
	&-holder {
		max-width: 1220px;
		margin: 0 auto;
		position: relative;
	}
	&-title {
		flex-shrink: 0;
		width: 268px;
		margin: 0 26px 0 0;
		border-right: 1px solid #000;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 3rem;
		line-height: 1.2;
		font-weight: 700;
		font-family: $typo-4;
		padding: 0 21px 0 0;
		color: $color-1--2;
		&-ico {
			display: flex;
			align-items: center;
			margin: 0 10px 0 0;
			img {
				width: 30px;
				height: 30px;
			}
		}
	}
	&-caption {
		background: $color-1--2;
		font-size: 1.3rem;
		line-height: 1.2;
		padding: 3px 10px;
		color: #fff;
		font-family: $typo-4;
		display:inline-block;
		vertical-align:top;
		margin: 0 0 6px;
	}
	p {
		font-family: $typo-5;
		font-size: 1.8rem;
		line-height: 1.2;
		font-weight: 400;
		margin: 0;
		
		a {
			color:$color-black;
			&:hover,
			&:focus {
				text-decoration:none;
				color:#ed135d;
			}
		}
	}
	&-close {
		position:absolute;
		top:-13px;
		right: 0;
		width: 40px;
		height: 40px;
		overflow: hidden;
		&:after,
		&:before {
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 1px;
			height: 30px;
			background: #000;
			content: '';
		}
		&:after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:focus {
			outline: 1px dashed #000;
		}
	}
}