// @name Wrapper with wide 940px
.wrapper-main {
    padding-left: 2rem;
    padding-right: 2rem;
}

.wrapper-1220 {
    padding: 0 2rem;
}

.section-main__wrapper {
    padding-bottom: 5.4rem;
    padding-top: 5.4rem;
}

.column-bloc {
    margin: 0 0 7.7rem;
    padding: 0;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
}

.column-bloc__wrapper {
    align-items: center;
    display: flex;
    width: 100%;
}
