.go-so-far {
    padding:45px 0;
}

.go-so-far__title {
    padding:0 0 15px;
    margin:0 0 4px;
    
    .divider--lg {
        bottom:-7px;
    }
}

.go-so-far__wrapper {
    width:104%;
}