.home-section_social {
   
   .home-section_heading {
      display:flex;
   }
   
   .filter-social {
      margin-top:-10px;
   }
   
   .social-list {
      
      li {
         width:50%;
      }
   }
   
   .social-list-content {
      padding:20px 20px 14px;
   }
   
   .social-list_img-holder + .social-list-content {
      padding-top:17px;
   }
}