.location {
    margin: 6rem 0;

    &__wrap {
        align-items: center;
        flex-direction: column;
    }

    &__information {
        margin-bottom: 3rem;
        padding-top: 0;
        width: 100%;

        &-block {
            margin-bottom: 1.7rem;
            max-width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-img {
            max-width: 10rem;
        }
    }

    &__map {
        margin-bottom: 2rem;
        margin-left: 0;
        max-width: 30rem;

        svg {
            height: 22rem;
        }
    }

    &__list {
        font-size: 1.2rem;
        padding: 0;

        &-title {
            font-size: 1.4rem;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        li {
            width: 46%;
        }

        a {
            padding-left: 1.2rem;
        }
    }
}
