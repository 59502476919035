.header-logo {
    margin-right: 0;
    width: 18rem;

    .logo-left {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-right: 0;

        strong {
            font-size: 1.8rem;
        }

        i {
            display: none;
        }
    }

    .link-logo {
        display: none;
    }
}
