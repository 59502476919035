// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.rte h3,
.rte .h3,
.h3 {
    margin:57px 0 20px;
    padding:0 0 17px;
}

.rte {

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}rem;
        margin: em(.5, $content-paragraph__font-size) 0 14px 0;
    }

    .ce-intext.ce-left,
    .ce-intext.ce-right {
        
        .ce-gallery {
            margin-top:41px;
            margin-bottom:25px;
        }
    }
    
    // @name Table
    .table-wrapper {
        position: relative;
        margin:38px 0;
    }
    
    .table-wrapper-fade {
        position: absolute;
        right: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
    }
    
    table {
        
        caption {
            text-align: left;
        }
        
        thead {
            
            th {
                padding:12px 30px;
            }
        }
        
        th,
        td {
            padding:12px 25px 13px;
            &:first-child {
                width:17%;
                padding-right:36px;
            }
        }
    }
    
    .encadre {
        padding:8px 20px 11px 79px;
        &:after {
            top:-18px;
            //background-size:100% auto;
        }
        
        .svg-icon {
            left:35px;
            top:47%;
        }
    }
    
    blockquote {
        margin:47px 0 51px;
        padding:0 14% 0 11%;
        &:before {
            top:-9px;
        }
        
        .divider {
            left:14.5%;
        }
    }
    
    .column--2,
    .column--3 {
        margin:30px -10pxn 0;
        overflow:hidden;
    }
    
    .column--3 {
        margin-top:0;
    }
    
    &.ddm__content {
        
        p {
            margin:0 0 18px;
        }
    }
}