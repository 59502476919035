.home-section_social {
   
   .home-section_heading {
      display:block;
   }
   
   .filter-social {
      justify-content:center;
      margin-top:23px;
   }
   
   .social-list {
      display:block;
      
      li {
         width:100%;
         padding: 0 15px 20px;
      }
   }
   
   .social-list_img-holder + .social-list-content {
       padding-top: 25px;
   }
   
   .social-list-content {
       padding: 20px;
   }
}