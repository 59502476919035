// Galerie classique
.classical-gallery {
    max-width: 70rem;
}

.classical-gallery__title {
    $classical-gallery__title_font-size: 1.6;
    font-family: $typo-2;
    text-align: right;
    font-weight: $normal;
    font-size: #{$classical-gallery__title_font-size}em;
    color: $color-3--3;
    margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
}

.classical-gallery {
    margin: 4em 0 5.8em;
    &__nb-items {
        display: none;
    }
}

.slider-galerie {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 8px;

    .slider-galerie__item {
        width: 100%;
        height: 100%;
        z-index: 1 !important;
        border: none;
        background: $color-3--1;
    }

    .infos-img {
        position: absolute;
        z-index: 1;
        top: 100%;
        right: 0;
        width: calc(100% - 510px);
        padding: 7px 10px;
        font-family: $typo-5;
        font-weight: $light;
        font-style:italic;
        color: #727272;
        font-size: 1.3em;
        white-space: normal;
        text-align: left;
        &__nbItems {
            display: table-cell;
            vertical-align: bottom;
            white-space: nowrap;
            width: 70px;
        }
        &__wrap {
            display: table-cell;
            vertical-align: top;
        }
        &__legend {
            display: block;
        }
    }

    a {
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        white-space: nowrap;
        padding: 68.08510% 0 0 0; // 100/(940/640)
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%) translateY(-50%);
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}

.sliderVideo {
    margin-bottom: 0;

    .infos-img {
        position: inherit;
        bottom: inherit;
        top: inherit;
        left: inherit;
        right: inherit;
    }
}

.carousel-galerie {
    width: 500px;
    display: inline-block;
    text-align: right;
    position: relative;
    z-index: 1;

    .item {
        border: none;
    }
}

.carousel-galerie__thumb {
    width: 340px;
    height: 74px;
    z-index: 2;
    margin-left: 80px;
    text-align: left;
    &.no-carousel {
        position: relative;

        .carousel-galerie__item {
            display: inline-block;
            margin: 0 3px;
        }
    }
}

.carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
        &:before {
            background: rgba(0, 0, 0, 0);
        }
    }
    button {
        position: relative;
        img {
            width: 74px;
            height: 74px;
        }
    }
    &.cycle-slide-active {
        button {
            background: $color-2--2;
            img {
                opacity: .5;
            }
        }
    }
}

.carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 500px;
    z-index: 1;
    margin: 0 !important;
}

.carousel-galerie__prev, .carousel-galerie__next {
    position: absolute !important;
    top: 0;
    padding: 0 !important;
    button {
        width: 74px;
        height: 74px;
        background: transparent;
        text-indent: -9999px;
        transition: all ease .2s;
        overflow: hidden;
        @include default-icons-absolute-before('\e026', em(2, 1.3), $color-2--2, 50%, inherit, inherit, 50%);
        &:before {
            text-indent: 0;
            transform: translateY(-50%) translateX(-50%);
        }
        &:hover, &:focus {
            background: $color-1--2;
            &:before {
                color: $color-2--2;
            }
        }
    }
    &:before {
        content: "";
        speak: none;
        display: none;
    }
}

.carousel-galerie__prev {
    left: 0;
}

.carousel-galerie__next {
    right: 0;
    button {
        &:before {
            content: "\e027";
        }
    }
}
