// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    
    .column-bloc__wrapper__text-holder {
        display:block;
        width:100%;
        padding:13px 0 0;
    }
    
    .bloc-event__item {
        display:flex;
    }
    
    .column-bloc__wrapper_holder {
        width:22%;
    }
    
    .link-bloc__context {
        
        > *:first-child {
            width:50%;
            flex-shrink:0;
            
            img {
                max-width:none;
                width:100%;
            }
        }
    }
    
    .date-1 {
        margin:3px 0 7px -37px;
        flex-shrink:0;
    }
    
    .category_event {
        margin: -17px 0 0 -22px;
    }
}
