.flux {
    margin: 0 0 3rem;
    .rte {
        margin-bottom: 40px;
    }
    &__listitems {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    margin: 0 -2%;
    > * {
            flex: 0 1 29.3333%;
    margin: 0 2% 3em;
        
    }
    }
    &__wrap {
       position: relative;
       .button-2 {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        z-index: 1;
       }
    }
    &__picture {
        display: block;
        margin-bottom: 2rem;
        img {
            width: 100%;
        }
    }
    &__header {
        margin-bottom: 5px;
    }
    &__title {
        font-size: 1.8rem;
        .link-block {
        color: #862183;

        }
    }
    &__select {
        select {
            margin-bottom: 20px;
            position: relative;
        z-index: 1;
        }
    }
}