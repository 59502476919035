// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {

    img {
        max-width: 100%;
        height: auto;
    }

    .bloc-event__wrapper-date {
        margin-bottom: 2em;
    }

    .bloc-event__wrapper > .date-1 {
        float: none;
        @media(min-width: 1280px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .bloc-event__title {
        $bloc-event__title__font-size: 2.4;
        font-family: $typo-4;
        font-weight: $bold;
        text-align:center;
        font-size: #{$bloc-event__title__font-size}rem;
        margin: 0 22px 22px;
        color: $color-black;

        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-2--2;
                text-decoration:none;
            }
        }

        span {
            position:relative;
            padding: 0 0 12px;
            margin:0 0 5px;

            &:after {
              content:'';
              position:absolute;
              left:50%;
              bottom:0;
              height:1px;
              width:220px;
              background:$color-2--2;
              transform:translateX(-50%);
           }
        }
    }

    .date-1 {
        margin-top:-39px;
        position:relative;
        z-index:1;

        span:first-child + time {
            margin-left: 0;
        }
    }

    .category_event {
        margin:-17px 0 0 -5px;
    }

    .hour-place {
        text-align: left;
    }

    .bloc-event_info {
        font-size:1.3rem;
        text-align:center;

        strong {
            display:block;
            font-size:1.5rem;
            font-weight:$bold;
            text-transform:uppercase;
            margin:0 0 -2px;
        }
    }
}
