// Focus
a, button, input[type="button"] {
    &:focus {
        outline: 1px dashed $color-1--2;
    }
}

// @name link-bloc
// @description Create an ergonomic link
.link-bloc__context {
    position: relative;
    }
    .link-bloc {
        &:after {
               position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
        }
    }


// @name Link view
// @description Link under feature (RSS of the feature + list of the feature)
.link-view {
    $link-view__item__font-size: 1.2;
    
    .link-view__feature {
        margin:0 0 4px;
    }
}

.column-bloc {
    
    .link-view {
        text-align:center;
    }
}
