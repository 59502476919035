// @name Videos
.videos {
    margin: 53px 0 66px;
}

.video__title {
    $video__title__font-size: 1.6;
    font-family: $typo-5;
    text-align:left;
    font-weight: $light;
    font-size: #{$video__title__font-size}em;
    color: #010101;
    margin: 0 0 8px 4px;
}

.videos__item {
    position: relative;
    width: 100%;
    margin: 0 auto 25px;
    
    video {
        width: 100%;
    }
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.videos__ratio {
    display: block;
    width: 100%;
    height: auto;
}

.ce-textpic {
    
    .videos,
    .videos__item {
        margin: 0;
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
