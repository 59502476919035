.location {
    overflow-x: hidden;
    margin: 4rem 0 5.5rem;
    position: relative;

    .home-section_title {
        margin-bottom: 0;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__information {
        color: $color-black;
        flex-shrink: 0;
        font-family: $typo-4;
        font-size: 1.4rem;
        font-weight: $normal;
        letter-spacing: -0.042rem;
        padding-top: 4.5rem;
        width: 25%;

        &-block {
            margin-bottom: 1.5rem;
            max-width: 18rem;
        }

        &-title {
            color: $color-1--2;
            font-weight: $bold;
            line-height: 2.2rem;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
        }
    }

    &__map {
        flex-shrink: 0;
        max-width: 56rem;
        position: relative;
        width: 100%;

        svg {
            display: block;
            height: 40rem;
            width: 100%;

            a {
                outline: none;
            }

            [data-active = 'true'] {
                fill: $color-2--2;
            }
        }

        .cls-1 {
            fill: $color-white;
            stroke: $color-2--4;

            &.active,
            &:focus,
            &:hover {
                cursor: pointer;
                fill: $color-2--2;
            }
        }

        .cls-2 {
            fill: $color-3--6;
        }

        &.-is-highlighted {
            .cls-1 {
                fill: $color-2--2;
                stroke: $color-white;
            }
        }
    }

    &__list {
        font-family: $typo-4;
        font-size: 1.4rem;
        font-weight: $normal;
        line-height: 1;
        padding: 4.5rem 0 0 16rem;
        text-transform: uppercase;

        &-title {
            color: $color-1--2;
            font-size: 1.8rem;
            font-weight: $bold;
            letter-spacing: -0.054rem;
            margin-bottom: 1rem;
        }

        li {
            margin-bottom: 0.3rem;
        }

        a {
            color: $color-black;
            display: inline-block;
            letter-spacing: -0.042rem;
            padding-left: 1.3rem;
            position: relative;

            &::before {
                content: '\e017';
                font-family: 'icons-default';
                font-size: 1.2rem;
                font-weight: $bold;
                left: 0;
                line-height: 1;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &.active {
                color: $color-2--2;
            }
        }
    }
}

.interactive-map-tooltip {
    background: $color-1--3;
    color: $color-white;
    font-size: 1.4rem;
    font-weight: $normal;
    line-height: 1;
    min-width: 10rem;
    padding: 1rem;
    pointer-events: none;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -150%);
    white-space: nowrap;
    z-index: 101;
}
