.site-infos {
    text-align: center;
    padding-top: 3rem;
    flex-direction: column;
    align-items: center;

    &-links {
        float: none;
        margin: 0 0 3rem;

        > ul {
            margin: 0;
        }
    }

    &__wrapper {
        max-width: 32rem;
        text-align: center;
    }

    p, ul {
        margin-right: 0;
    }
}
