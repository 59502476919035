// @name Subtitle or category
$category__font-size: 1.6;
.subtitle,
.category {
    font-family: $typo-4;
    font-weight: $bold;
    font-size: #{$category__font-size}em;
    text-transform: uppercase;
    color: $color-1--2;
}

.category {
    text-align:left;
    font: $bold 1.3rem/1.2 $typo-4;
}

.category_event {
    display:inline-block;
    vertical-align:top;
    text-align: right;
    font: $bold 1.3rem/1.2 $typo-4;
    text-transform:uppercase;
    color: #fff;
    margin:-17px 0 0 -5px;
    span {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 103px;
        min-height: 39px;
        padding: 10px 13px 10px;
        margin: 0 0 0 -10px;
        overflow: hidden;
        z-index: 1;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $color-1--2;
            transform: translate(9px, -4px) rotate(5deg) skew(-20deg, 0);
            z-index: -1;
        }
    }
}

// @name caption
// @description Default caption
.caption {
    font-size: 1.3rem;
    font-weight: $light;
    font-style: italic;
    font-family: $typo-5;
    color: $color-3--3;
}

// @name Date
// @description Default date
.date {
    $date__font-size: 1.2;
    font-size: #{$date__font-size}em;
    font-weight: $normal;
    font-family: $typo-2;
    color: $color-3--3;
    margin: em(0.6, $date__font-size) 0;
    &.date--big {}
}

// @name Date 1
// @description Date in event
.date-1 {
    float:left;
    position: relative;
    width: 124px;
    min-height: 71px;
    padding: 9px 10px 12px;
    margin: 0 0 24px;
    font-family: $typo-4;
    font-weight: $bold;
    color: $color-black;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 2;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(141, 198, 63, .95);
        transform: translate(-5px, -6px) rotate(-4deg) skew(-12deg, 9deg);
        z-index: -1;
    }

    time {
        display: inline-block;
        vertical-align: middle;
        margin: 0 1em;
        width:29%;

        + span + time {
            position: relative;

            &:before {
                content: "\e017";
                font-family: "icons-default";
                font-size: 1.4rem;
                line-height: 1;
                color: #000;
                position: absolute;
                top: 37%;
                left: -1.5rem;
            }
        }
    }

    .time-holder {
        float:left;
        width:50%;
        &:nth-child(2) {
            position:relative;
            &:before {
                content:'>';
                position:absolute;
                left:-2px;
                top:50%;
                transform:translateY(-50%);
                color:$color-black;
            }
        }
    }

    .day,
    .month {
        display: block;
        line-height: 1;
    }

    .day {
        font-size: 2.8rem;
    }

    .month {
        font-size: 1.6rem;
    }
}

// @name Date 2
// @description Date in job
$date-2__font-size: 1.4;
$date-2--big__font-size: 2;
.date-2 {
    font-size: #{$date-2__font-size}em;
    text-transform: uppercase;
    color: $color-1--2;
    margin: 0 0 em(.8, $date-2__font-size);
    time {
        font-weight: $bold;
    }
    &.date-2--big {
        font-size: #{$date-2--big__font-size}em;
        margin: em(.5, $date-2--big__font-size) 0 em(.8, $date-2--big__font-size);
    }
}

// @name Date 3
// @description Date in public market
.date-3 {
    $date-3__font-size: 1.2;
    @include default-icons-absolute-before('\e05b', em(1.2, 1.2), $color-1--2, em(1.3, 1.2), inherit, inherit, 0);
    font-family: $typo-2;
    font-size: #{$date-3__font-size}em;
    font-weight: $bold;
    text-transform: uppercase;
    padding-top: em(1.2, $date-3__font-size);
    padding-left: em(1.8, $date-3__font-size);
    margin-bottom: em(1.2, $date-3__font-size);
    border-top: 1px solid darken($color-3--1, 20%);
    time {
        font-weight: $light;
    }
    &.date-3--big {
        $date-3--big__font-size: 1.6;
        font-size: #{$date-3--big__font-size}em;
        padding-top: em(1.2, $date-3--big__font-size);
        padding-left: em(2, $date-3--big__font-size);
        margin-bottom: em(1.2, $date-3--big__font-size);
        margin-top: em(1.2, $date-3--big__font-size);
        &:before {
            font-size: em(1.6, $date-3--big__font-size);
            top: em(1.3, $date-3--big__font-size);
        }
        br {
            display: none;
        }
    }
}

// @name Date 4
// @description Date in publications bloc
.date-4 {
    $date-4__font-size: 1.5;
    font-size: #{$date-4__font-size}em;
    text-transform: uppercase;
    font-weight: $light;
    margin: 0 0 em(2, $date-4__font-size);
    time {
        & + span + time {
            @include default-icons-before('\e017', 0 5px 0 0, em(1.4, $date-4__font-size), $color-black, em(-.1, 1.4));
        }
    }
}

// @name Media
// Media in publications, public market, albums
.media {
    border-top: 1px solid darken($color-3--1, 20%);
    color: $color-3--3;
    text-transform: uppercase;
    font-size: 1.2em;
    padding-top: em(1.2, 1.2);
}

// @name Hour and place wrapper
.hour-place {
    text-align: center;
}

// @name Hour
// @description Default hour
.hour {
    $hour__font-size: 1.8;
    font-family: $typo-2;
    display: block;
    font-weight: $bold;
    font-size: #{$hour__font-size}em;
    margin-bottom: em(0.5, $hour__font-size);
}

// @name Place
// @description Default place
.place {
    font-size: 1.4em;
    font-family: $typo-4;
    font-weight: $light;
    font-style: italic;
}

// @name Teaser 1
// @description Teaser in list view
.teaser-1 {
    $teaser-1__font-size: 1.3;
    font-size: #{$teaser-1__font-size}em;
    margin: em(.8, $teaser-1__font-size) 0;
}

// @name Teaser 2
// @description Teaser in heading
$teaser-2__font-size: 1.8;
.teaser-2 {
    font-size: #{$teaser-2__font-size}rem;
    line-height: #{$teaser-2__font-size + 0.8}rem;
    //color: $color-white;
    margin: em(1.2, $teaser-2__font-size) 0 7px;
}

// @name Teaser 3
// @description Teaser in bloc of right column
.teaser-3 {
    $teaser-3__font-size: 1.6;
    font-size: #{$teaser-3__font-size}em;
    font-weight: $normal;
    margin: em(.8, $teaser-3__font-size) 0;
}

// @name status
// @description Status for public market
.status {
    $status__font-size: 1.2;
    font-size: #{$status__font-size}em;
    background: $color-3--1;
    padding: em(.5, $status__font-size);
    font-family: $typo-2;
    font-weight: $light;
    text-transform: uppercase;
    margin: 0 0 em(.8, $status__font-size);
    @include default-icons-before('\e057', 0 5px 0 0, em(1, $status__font-size), $color-3--3, 0);
    &.status--new {
        background: $color-1--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
    &.status--in-progress {
        background: $color-2--2;
        color: $color-white;
        &:before {
            content: "\e093";
            color: $color-white;
        }
    }
}

// @name Message
// @state error - Display error message
$error__font-size: 1.4;
.message {
    background: $color-3--1;
    font-size: #{$error__font-size}em;
    font-weight: $normal;
    padding: 3.2rem 3rem 3rem 7.6rem;
    position: relative;
    font-family: $typo-5;
    &:before {
        content: attr(aria-label);
        display: block;
        color: $color-black;
        font-size: 2rem;
        font-family: $typo-4;
        font-weight: $bold;
        margin-bottom: em(2, 2);
    }
    &:after {
        position: absolute;
        top: 34px;
        left: 39px;
        font-family: icons-default;
        font-size: 25px;
        line-height: 1;
        color: #ff000c;
        content: '\e07f';
    }
    > li {
        padding-left: em(1.5, $error__font-size);
        @include default-icons-absolute-before('\e027', em(1.2, $error__font-size), $color-black, em(.4, 1.2), inherit, inherit, 0);
        margin: em(.5, $error__font-size) 0;
    }
    &.message--error {
        background: #fcdcde;
        &:before {
            color: $color-red;
        }
    }
    &.message--succes {
        background: lighten($color-green, 65%);
        &:before {
            color: $color-green;
        }
    }
}

// @name No result
.no-result {
    font-size: 1.5em;
    font-weight: $light;
    text-align: center;
}


.price {
    font-size: 2rem;
    color: #de981b;
    font-weight: 700;
}

.description {
    font-size: 1.3rem;
    margin: 5px 0;
}
