.filters {
    padding: 30px 0 30px;
    form {
        margin: 2em 0 0;
    }
    &__header {
        .button-2 {
            border: 1px solid #000;
            &:hover,
            &:focus {
                border-color: #fdb73b;
            }
        }
    }
}

.ddm {
    .filters__ddm {}
    .filters__ddm__sub-level {
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: max-height ease .2s;
    }
    &.ddm--active {
        .filters__ddm__sub-level {
            max-height: 100em;
            transition: max-height ease 1s;
        }
    }
}

.filters__wrapper {
    display: block;
    table-layout: inherit;
}

.filters__fields,
.filters__button-wrapper {
    display: block;
    vertical-align: inherit;
}

.filters__fields {
    .filters__fields-wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        margin: 0;
        > .filters__field-wrapper {
            align-self: inherit;
            flex: inherit;
            margin-right: 0;
            margin-left: 0;
            .filters__field-1 {
                display: block;
                text-align: left;
            }
            .filters__field-2 {
                display: block;
            }
        }
    }
    &.filters__fields--column-2,
    &.filters__fields--column-3,
    &.filters__fields--column-4 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                width: 100%;
            }
        }
    }
}

.filters__button-wrapper {
    padding-left: 0;
    padding-top: 0;
    text-align: center;
    width: 100%;
    
    .views-reset-button,
    .views-submit-button {
        display:inline-block;
        vertical-align:top;
    }
}
