// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.rte h2,
.rte .h2,
.h2 {
    font-size:2rem;
    margin:0 0 24px;
    &:before {
        top:-10px;
    }
}

.rte h3,
.rte .h3,
.h3 {
    font-size:1.8rem;
    margin:40px 0 21px;
    padding:0 0 19px;
}

.rte h4,
.rte .h4,
.h4 {
    font-size:1.6rem;
    margin:40px 0 15px;
}

.rte h5,
.rte .h5,
.h5 {
    font-size:1.5rem;
    margin:30px 0 20px;
}

.rte h6,
.rte .h6,
.h6 {
    font-size:1.4rem;
    margin:30px 0 15px;
}

.rte {

    // @name Paragraph
    $content-paragraph__font-size: 1.4;

    p {
        font-size: #{$content-paragraph__font-size}rem;
        line-height:1.25;
        margin: em(.5, $content-paragraph__font-size) 0 8px;
    }

    .ce-textpic {
        display:flex;
        width:100%;
        flex-wrap:wrap;

        .ce-gallery {
            order:3;
        }
    }

    .ce-intext.ce-left .ce-gallery,
    .ce-intext.ce-right .ce-gallery {
        margin:21px auto 0;
    }

    .ce-intext.ce-left {

        p {
            margin-top:0;
        }
    }

    .ce-gallery {

        figcaption {
            letter-spacing:-0.5px;
        }
    }

    .table-wrapper {
        margin:54px 0 41px;
    }

    .table-wrapper-inner {
        max-width: 100%;
    }

    table {
        width:725px;

        caption {
            font-size:1.3rem;
        }

        tbody {

            th,
            td {
                padding-top:13px;
            }
        }
    }

    .encadre {
        margin:27px 0 38px;
        padding:13px 15px 4px 69px;
        &:after {
            transform: rotate(-1deg);
        }
        &:before  {
            bottom:-23px;
            transform: rotate(-3deg);
        }
        .svg-icon {
            transform:none;
            top:15px;
            left:21px;
        }
    }

    ul,
    .show-hide__content ul {
        font-size:1.4rem;
        margin-bottom:29px;

        > li {

            > ul:first-child,
            > a + ul {
                margin:1px 0 -7px 3px;
            }
        }
    }

    p + ol,
    p + .show-hide__content ol {
        margin-top:15px;
    }

    ol,
    .show-hide__content ol {
        font-size:1.4rem;
        margin-bottom:29px;
        margin-left:22px;

        > li {

            > ul:first-child,
            > a + ul {
                margin:1px 0 -7px 3px;
            }
        }
    }

    blockquote {
        margin: 58px 0 32px;
        padding: 0 14% 0 16.5%;
        font-size:1.8rem;
        &:before {
            left:-12px;
            top:-7%;
            font-size:62px;
        }
    }

    .column--2,
    .column--3 {
        margin:0;
        display:block;
        columns: 1;

        > * {
            width:100%;
            padding:0;
        }
    }
}

// @name Video Iframe
.rte {
    .field, .field-items {
        iframe {
            margin-left: auto;
            margin-right: auto;
            height: 28rem;
        }
    }
}
