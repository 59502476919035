// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-3--1;
    padding: 71px 0 80px;
    
    &__title {
        margin: 0 0 10px;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -2%;
        > * {
            flex: 1;
            margin: 0 2%;
            &:nth-child(3) {
                flex: 2;
            }
        }
    }
}