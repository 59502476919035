.header-link {
    position: relative;
    padding-top: 10px;
    //&:before {
    //    position: absolute;
    //    top: -34px;
    //    left: 0;
    //    width: 1px;
    //    height: 97px;
    //    background: #ccc;
    //    content: '';
    //}
    a {
        display: block;
        padding-left: 20px;
        font-family: $typo-4;
        font-size: 1.3rem;
        color: #000;
        text-transform: uppercase;
        line-height: 1;
    }
}
