.location-block {
  display:flex;
  margin:0 0 3em;
  background:$color-3--1;
}

.ddm.ddm__location {
  margin:69px 0 28px;
    
    .ddm__button {
        cursor:text;
        pointer-events:none;
        background:none;
        border:none;
        font:700 3rem/1.2 $typo-4;
        text-transform:uppercase;
        color:$color-1--2;
        margin:0 0 10px;
        padding:0;
        &:before,
        &:after {
            display:none;
        }
    }
    
    .ddm__sub-level {
        height:auto;
    }
}

.location-block_map-box {
  position:relative;
  width:calc(100% - 220px);
  overflow:hidden;
  
  .img-placeholder {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:auto;
    height:auto;
    min-width:100%;
    min-height:100%;
    max-width:none;
  }
}

.location-block_info-box {
  width:220px;
  display:flex;
  flex-shrink:0;
  flex-direction:column;
  justify-content:space-between;
  padding:27px 16px 40px;
  min-height:467px;
  
  .button {
    font-family:$typo-4;
    font-size:1.4em;
    padding:14px 24px;
    width:100%;
    text-align:center;
    & + .button {
      margin-top:10px;
    }
  }
}

.location-block_wrapper {
  padding:0 18px;
  
  > * {
    margin:0 0 10px;
    font-size:1.4rem;
    line-height:1.2;
    font-family:$typo-5;
    font-weight:$light;
  }
  
  strong {
    display:block;
    font-size:1.9rem;
    font-family:$typo-4;
    letter-spacing:1px;
    color:$color-black;
    margin:0 0 12px;
  }
  
  p {
    font-size:inherit;
    margin:0 0 12px;
  }
}

.location-block_item {
  @include default-icons-absolute-before('\e053', 12px, $color-2--2, 2px, inherit, inherit, 0);
  
  a {
    position:relative;
    display:inline-block;
    vertical-align:top;
    padding-left:15px;
    color: $color-black;
    font-weight:$light;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
  &.location-block_address {
    &:before {
      content: "\e053";
    }
  }
  &.location-block_website {
    &:before {
      content: "\e089";
    }
    a {
      text-decoration:underline;
      &:hover {
        text-decoration:none;
      }
    }
  }
  &.location-block_phone {
    &:before {
      content: "\e02b";
    }
  }
  &.location-block_fax {
    &:before {
      content: "\e09f";
    }
  }
  &.location-block_email {
    &:before {
      content: "\e028";
    }
    
    a {
      text-decoration:underline;
      &:hover {
        text-decoration:none;
      }
    }
  }
  &.location-block_infos {
    &:before {
      content: "\e083";
    }
  }
  &.location-block_hours {
    &:before {
      content: "\e05b";
    }
  }
}