// @name Search box
// @description Search module
.search-box {
    margin-left: 3rem;

    form {
        margin: 0;
    }
     //Drop down menu
    .ddm {
        position: static;

        &__title {
            button {
                height: 62px;
                margin-top: -8px;
                padding: 0;
                position: relative;
                width: 60px;
                z-index: 101;

                svg {
                    height: 1.6rem;
                    width: 1.6rem;
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    svg {
                        fill: $color-1--2;
                    }
                }
            }
        }
        &--active {
            .ddm__title {
                button {
                    svg {
                        display: none;
                    }
                    &:after {
                        color: #fff;
                        content: '\e022';
                        font-family: 'icons-default';
                        font-size: 20px;
                        font-weight: normal;
                        left: 50%;
                        line-height: 1;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    &__fields-wrapper {
        align-items: center;
        display: flex;
    }

    &__field-wrapper {
        position: relative;

        input {
            background-color: $color-1--2;
            border: 0.1rem solid $color-white;
            color: $color-white;
            font-family: $typo-4;
            font-size: 1.6rem;
            font-weight: $normal;
            height: 4.9rem;
            letter-spacing: -0.048rem;
            padding: 0 1.6rem;
            width: 26.5rem;

            @media (max-width: 1340px) {
                width: 20rem;
            }
        }

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $color-white;
            opacity: 1;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: $color-white;
            opacity: 1;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: $color-white;
            opacity: 1;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: $color-white;
            opacity: 1;
        }

        .search-box__button {
            background-color: $color-1--2;
            border: 0;
            height: 4.7rem;
            position: absolute;
            right: 0.1rem;
            top: 0.1rem;
            width: 4.7rem;

            svg {
                height: 1.6rem;
                width: 1.6rem;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                svg {
                    fill: $color-2--2;
                }
            }
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .search-box .ddm > .ddm__sub-level {
        right: 50px;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    border: 1px solid darken($color-3--1, 20%);
    height: em(1, 1.2);
    width: em(6, 1.2);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        background: $color-1--2;
        height: 100%;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    meter {
        display: none;
    }
}
