.partners-gallery {
    padding: 7px 20px 15px;
    
    .listItems {
        .item {
            padding: 0;
            
            a {
                margin: 0;
            }
        }
    }
    
    .partners-gallery_img {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 260px;
        margin: 0 auto 15px;
    }
    
    h3 {
        display:inline-block;
        vertical-align:top;
        min-width:182px;
    }
    
    .prevNextCaroussel3 {
        margin: 0;
        .prevCaroussel3,
        .nextCaroussel3 {
            margin-top:-15px;
        }
    }
}

.home-section_partners {
   
   .home-section_heading {
      margin-bottom:-23px;
   }
}