.home_stage-games-section {
   
   .home-section_heading {
      
      .stage-games_btn-more {
         position:absolute;
         right:0;
         top:2px;
      }
   }
}