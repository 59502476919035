// @name Search box
// @description Search module
.search-box {
    margin-left: 1rem;
    margin-top: 0;

    .ddm {
        > .ddm__sub-level {
            background-color: $color-3--1;
            padding: 2.1rem 9rem 2.1rem 2rem;
            right: 0;
            top: 0;
            width: calc(100% - 44rem);
        }
    }

    &__field-wrapper {
        width: 100%;

        input {
            width: 100%;
        }
    }

    .ddm__title {
        button {
            background: $color-2--2;
            height: 4.9rem;
            margin-top: 0;
            width: 4.9rem;
        }
    }

    &__fields-wrapper {
        label {
            display: none;
        }
    }
}
