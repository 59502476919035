.home-section_social {
   $color-social-fb:#3b5998;
   $color-social-tw:#00acee;
   $color-social-ins:#833ab4;
   $color-social-yt:#c4302b;
   
   .icons-default {
      width:20px;
      height:20px;
   }
   
   .icon-facebook {
      font-size:20px;
      content: "\e099";
      color:#fff;
   }
   
   .filter-social {
      display:flex;
      align-items:center;
      margin:-5px 0 0;
      padding:0;
      list-style:none;
      font:$bold 1.2rem/1.2 $typo-4;
      
      li {
         margin:0 1px 0 0;
      }
      
      a {
         display:flex;
         align-items:center;
         justify-content:center;
         width: 34px;
         height: 34px;
         background:$color-3--1;
         @include default-icons-absolute-before("\e099", 16px, $color-black, 50%, inherit, inherit, 50%, 'icons-default');
         &:before {
            color:#525252;
            font-weight:$normal;
            transform:translate(-50%, -50%);
         }
         &.active {
            width:50px;
            height:54px;
            text-decoration:none;
            &:before {
               color:#fff;
            }
         }
         &:hover,
         &:focus {
            background:$color-3--1 - 20;
            text-decoration:none;
            &:before {
               color:#fff;
            }
         }
      }
      
      .filter-social-all {
         background: url(../Images/bg/bg-button.png) no-repeat;
         background-size: cover;
         width:50px;
         height:54px;
         color:$color-black;
         text-transform:uppercase;
         &:before {
            display:none;
         }
         &:hover,
         &:focus {
            background: url(../Images/bg/bg-button.png) no-repeat;
            background-size: cover;
            opacity:0.85;
         }
      }
      
      .filter-social-fb {
         &.active,
         &:hover,
         &:focus {
            background:$color-social-fb;
         }
      }
      
      .filter-social-tw {
         &.active,
         &:hover,
         &:focus {
            background:$color-social-tw;
         }
         &:before {
            content: "\e09b";
         }
      }
      
      .filter-social-ins {
         &.active,
         &:hover,
         &:focus {
            background:$color-social-ins;
         }
         &:before {
            content: "\e0a1";
         }
      }
      
      .filter-social-yt {
         &.active,
         &:hover,
         &:focus {
            background:$color-social-yt;
         }
         &:before {
            content: "\e09c";
         }
      }
   }
   
   .social-list {
      display:flex;
      flex-wrap:wrap;
      margin:0 -10px 10px;
      padding:0;
      list-style:none;
      
      li {
         width:25%;
         padding:0 10px 20px;
      }
   }
   
   .social-list-ico {
      display:flex;
      align-items:center;
      justify-content:center;
      height:35px;
      @include default-icons-absolute-before("\e099", 16px, $color-black, 50%, inherit, inherit, inherit, 'icons-default');
      &:before {
         color:#fff;
         transform:translateY(-50%);
      }
   }
   .social-list-ico-fb {
      background:$color-social-fb;
   }
   .social-list-ico-tw {
      background:$color-social-tw;
      &:before {
         content: "\e09b";
      }
   }
   .social-list-ico-ins {
      background:$color-social-ins;
      &:before {
         content: "\e0a1";
      }
   }
   .social-list-ico-yt {
      background:$color-social-yt;
      &:before {
         content: "\e09c";
      }
   }
   
   .social-list_img-holder + .social-list-content {
      padding-top:24px;
   }
   
   .social-list_img-holder {
      display:block;
      position:relative;
      &.video {
         &:before,
         &:after {
            content:'';
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
            z-index:1;
         }
         &:before {
            width: 50px;
            height: 50px;
            opacity: 0.5;
            background-color: white;
            border-radius:100%;
         }
         &:after {
            content: "\e075";
            font-family: icons-default;
            font-size:20px;
            color: #111212;
         }
      }
      
      img {
         display:block;
         width:100%;
      }
   }
   
   .social-list-content {
      padding:20px;
      font:$normal 1.3rem/1.2 $typo-4;
      color:$color-black;
      border: 1px solid #dedede;
      
      a {
         color:$color-social-fb;
         &:hover,
         &:focus {
            color:$color-1--2 !important;
            text-decoration:none;
         }
      }
   }
   
   .social-list-head {
      display:flex;
      margin:0 0 18px;
   }
   
   .social-list-head-ico {
      flex-shrink:0;
      width:44px;
      margin:0 10px 0 0;
   }
   
   .social-list-head-text {
      font-size:1.1rem;
      font-weight:$light;
      color:#717171;
   }
   
   .social-list-title {
      display:block;
      font-size:1.4rem;
      font-weight:$bold;
      margin:0 0 5px;
      
      a {
         color:$color-social-fb;
      }
   }
   
   .social-list-link {
      color:#717171 !important;
      &:hover,
      &:focus {
         color:$color-1--2 !important;
      }
   }
   
   .social-list-mark-text {
      
      a {
         color:#154452 !important;
         &:hover,
         &:focus {
            color:$color-1--2 !important;
         }
      }
   }
   
   p {
      margin:0;
   }
   
   .social-list-date {
      font-size:1.1rem;
      font-weight:$normal;
      text-transform:uppercase;
      color:$color-social-fb;
      margin:11px 0 7px;
   }
   
   .social-list-instagram {
      
      .social-list-content {
               
         a {
            color:$color-social-ins;
         }
         
         p {
            
            a {
               color:$color-black;
            }
         }
      }
      
      .social-list-date {
         color:$color-social-ins;
      }
   }
   
   .social-list-twitter {
      
      .social-list-content {
               
         a {
            color:$color-social-tw;
         }
         
         p {
            
            a {
               color:$color-black;
            }
         }
      }
      
      .social-list-date {
         color:$color-social-tw;
      }
   }
   
   .social-list-youtube {
      
      .social-list-content {
               
         a {
            color:$color-social-yt;
         }
         
         p {
            
            a {
               color:$color-black;
            }
         }
      }
      
      .social-list-date {
         color:$color-social-yt;
      }
   }
}