// Color contrast
// Function that calculates if color needs to be light or dark.
@function color-contrast($color) {
    @return if(lightness($color) < 51, #FFF, #000);
}

// Transform Hexa to RGBA
@mixin background-opacity($color, $opacity: 0.5) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

// Calcul em size depending of the context
// Use em(fontSizeDoIWantInEm, fontContextInEM)
// padding: 0 em(3.5, 2.2) 0 em(5, 2.2);
@function em($fontSize, $context) {
    @return #{$fontSize/$context}em;
}

// Reset font icon
@mixin resetIcons() {
    &:before {
        font-family: none;
        content: none;
        margin: auto;
    }
}

// Create fontFace
@mixin fontFace($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
    @font-face {
        font-family: "#{$fontname}";
        src: url("#{$font-path}#{$fontfile}.eot");
        src: url("#{$font-path}#{$fontfile}.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}#{$fontfile}.woff") format("woff"),
        url("#{$font-path}#{$fontfile}.ttf") format("truetype"),
        url("#{$font-path}#{$fontfile}.svg##{$fontname}") format("svg");
        font-weight: $fontweight;
        font-style: $fontstyle;
    }
}

// Add inline icon before
@mixin default-icons-before($icon, $margin: 0 5px 0 0, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:before {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-transform: inherit;
    }
}

// Add inline icon after
@mixin default-icons-after($icon, $margin: 0 0 0 5px, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:after {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-decoration: none;
    }
}

// Add absolute icon before
@mixin default-icons-absolute-before($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:before {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add absolute icon after
@mixin default-icons-absolute-after($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:after {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add image before
@mixin img-before($position: relative, $bgColor: transparent, $folderName: $image-bg-url, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:before {
        content: '';
        background: $bgColor url("#{$folderName}/#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

// Add image after
@mixin img-after($position: relative, $bgColor: transparent, $folderName: $image-bg-url, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:after {
        content: '';
        background: $bgColor url("#{$folderName}/#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

// mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
    // Possible values for $pseudo are: before, after, both
    @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
        position: relative;
        z-index: 1;
        $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

        #{$selector} {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        @if $pseudo == 'before' {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo == 'after' {
            #{$selector} {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo == 'both' {
            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }
    }
}

/// Dependency - str-replace mixin from https://css-tricks.com/snippets/sass/str-replace-function/
/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Encode symbols
@function url-encode($string) {
    $map: (
        "%": "%25",
        "<": "%3C",
        ">": "%3E",
        " ": "%20",
        "!": "%21",
        "*": "%2A",
        "'": "%27",
        '"': "%22",
        "(": "%28",
        ")": "%29",
        ";": "%3B",
        ":": "%3A",
        "@": "%40",
        "&": "%26",
        "=": "%3D",
        "+": "%2B",
        "$": "%24",
        ",": "%2C",
        "/": "%2F",
        "?": "%3F",
        "#": "%23",
        "[": "%5B",
        "]": "%5D"
    );

    $new: $string;

    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }

    @return $new;
}

// Format the SVG as a URL
@function inline-svg($string) {
    @return url('data:image/svg+xml,#{url-encode($string)}');
}

@mixin bg-pattern($color, $opacity: 1, $property: 'background-image') {
    #{$property}: inline-svg('<svg fill="#{$color}" fill-opacity="#{$opacity}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 3"><rect width="1" height="1"/></svg>');
    background-size: 3px;
}
