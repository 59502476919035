// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    
    .bloc-event__item {
        display:block;
    }
    
    .column-bloc__wrapper_holder {
        width:100%;
        text-align:center;
    }
    
    .date-1 {
        float:none;
        display:block;
        margin:-31px auto 6px;
    }
    
    .category_event {
        margin: -17px 0 0;
    }
    
    .column-bloc__wrapper__text-holder {
        padding: 5px 0 0;
    }
}
