//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.4;
.rte h2, .rte .h2, .h2 {
    position:relative;
    font-family: $typo-4;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $bold;
    color: $color-1--2;
    margin: 0 0 26px;
    letter-spacing:-1px;
    &:before {
        content:'';
        position:absolute;
        left:0;
        top:-14px;
        width:46px;
        height:1px;
        background:$color-1--2;
    }

    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.8;
.rte h3, .rte .h3, .h3 {
    font-family: $typo-4;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $normal;
    color: $color-black;
    margin: 57px 0 27px;
    padding:0 0 9px;
    letter-spacing:-1px;
    border-bottom:1px solid $color-3--4;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2.1;
.rte h4, .rte .h4, .h4 {
    font-family: $typo-4;
    font-size: #{$content-h4__font-size}rem;
    line-height: 1.2;
    font-weight:$bold;
    color: $color-black;
    margin: em(4, $content-h4__font-size) 0 14px 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.8;
.rte h5, .rte .h5, .h5 {
    font-size: #{$content-h5__font-size}em;
    line-height: 1.2;
    font-weight:$normal;
    color: $color-black;
    margin: 30px 0 18px;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6, .rte .h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: 30px 0 15px 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.rte {
    font-family:$typo-5;
    font-weight: $light;

    // @name Link
    a {
        color: $color-1--2;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    //  @name External link
    // a[href^="http://"]:not([href*="#{$domain}"]),
    // a[href^="https://"]:not([href*="#{$domain}"]),
    // a[href^="//"]:not([href*="#{$domain}"]) {
    a.link {
        @include default-icons-after('\e06d', 0 0 0 5px, 12px, $color-1--2, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: #{$content-paragraph__font-size}em;
        margin: 0 0 13px;
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        $encadre__font-size: 1.6;
        position:relative;
        padding: 10px 30px 10px 110px;
        font-size: #{$encadre__font-size}rem;
        margin: 31px 0 31px;
        &:after {
            content:'';
            position:absolute;
            left: 0px;
            top: -25px;
            right: 4px;
            bottom: -21px;
            background:url(../Images/bg//bg-encadre.svg) no-repeat 100% 0;
            background-size:cover;
            z-index:-1;
        }
        &:before {
            content:'';
            position:absolute;
            left:-15px;
            top:-10px;
            bottom:-13px;
            width:20px;
            background:$color-white;
            transform:rotate(-7deg);
            z-index:1;
            overflow:hidden;
        }

        .svg-icon {
            position:absolute;
            left:50px;
            top:50%;
            width:27px;
            height:38px;
            transform:translateY(-50%);
            fill:$color-2--2;
        }

        a {
            color:$color-black;
            font-weight:$normal;
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 2.2;
    $content-blockquote-icon-1__font-size: 6.5;
    blockquote {
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-3--1, -23px, inherit, inherit, 1.5%);
        font-size: #{$content-blockquote__font-size}rem;
        &:before {
            font-style: normal;
            z-index:-1;
        }
        padding:0 10% 0 9.7%;
        margin:51px 0 49px;
        font-weight: $light;
        line-height:1.2;
        color:$color-1--2;

        p {
            position:relative;
            font-size: 1em;
            font-weight: $light;
            margin:0;
            &:before {
                content:'';
                position:absolute;
                left:2px;
                top:-12px;
                width:46px;
                height:1px;
                background:$color-1--2;
            }
        }
    }

    h5 + ul,
    .h5 + ul {
        margin-top:-5px;
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: 15px 0 1.5rem 19px;
        list-style: none;
        font-size: 1.5rem;
        color: $color-black;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            @include default-icons-absolute-before('\e027', 1.1rem, $color-2--2, 3px, inherit, inherit, 4px);
            padding-left: 2rem;
            margin:0 0 14px;
            > ul:first-child, > a + ul { // for specificity selector
                margin: 3px 0 -5px 4px;
                list-style: none;
                padding: .1rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('-', 1.5rem, $color-black, -2px, inherit, inherit, 7px);
                    padding-left: 1.5rem;
                    margin:0 0 4px;
                }
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: 3px 0 -5px 28px;
                padding: .1rem 0 .3rem 0;
                > li {
                    padding: 0;
                    margin:0 0 4px;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    h5 + ol,
    .h5 + ol {
        margin-top:-5px;
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: 1px 0 1.5rem 2.5rem;
        padding: 0 0 0 1.8rem;
        list-style: decimal;
        font-size: 1.5rem;
        color: $color-black;
        p {
            font-size: #{$content-paragraph__font-size}rem;
        }
        > li {
            padding: 0;
            margin:0 0 14px;
            > ol:first-child, > a + ol { // for specificity selector
                margin: 3px 0 -5px 28px;
                padding: .1rem 0 .1rem 0;
                > li {
                    padding: 0;
                    margin:0 0 4px;
                }
            }
            > ul:first-child, > a + ul { // for specificity selector
                margin: 4px 0 -5px -6px;
                list-style: none;
                padding: .1rem 0 .3rem 0;
                > li {
                    //@include default-icons-absolute-before('\e06c', .25rem, $color-black, .25rem, inherit, inherit, 0);
                    @include default-icons-absolute-before('-', 1.5rem, $color-black, -2px, inherit, inherit, 12px);
                    margin:0 0 4px;
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 45px 0 39px;
    }

    .table-wrapper-inner {
        //max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }

    table {
        width: 100%;
        table-layout:fixed;
        font-size: 1.4rem;
        color:$color-black;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-5;
            text-align: left;
            font-weight: $light;
            font-size: #{$table__caption_font-size}rem;
            color: #010101;
            margin-bottom: 21px;
        }
        th, td {
            padding: 12px 30px 13px;
            width:19%;
            vertical-align:middle;
            &:first-child {
                width:18%;
                padding-right:23px;
            }
        }
        th {
            text-transform: uppercase;
            font-family: $typo-5;
            font-weight: $bold;
            text-align: left;
        }
        thead th {
            background: $color-white;
            font-weight: $bold;
            border-style:solid;
            border-width:0 0 1px;
            border-color:$color-2--2;
        }
        tbody th {
            text-align: right;
            border-style:solid;
            border-width:1px 0 0;
            border-color:$color-3--4;
        }
        td {
            color: $color-black;
            background: $color-white;
            border-style:solid;
            border-width:1px 0 0;
            border-color:$color-3--4;
        }
    }

    sup {
        color:$red;
        font-size:0.65em;
    }

    // @name column 2
    .column--2,
    .column--3 {
        margin: 36px -10px 25px;
        display:flex;

        > * {
            width:50%;
            padding:0 10px;
        }
    }

    .column--3 {
        margin-top:26px;

        > * {
            width:33.33%;
        }
    }

    .ce-intext.ce-left {

        .ce-gallery {
            width:280px;
            margin-top:7px;
            overflow:hidden;
        }

        p {
            margin-top:-7px;
            &:last-child {
                margin-top:0;
            }
        }
    }

    .ce-gallery {

        figcaption {
            padding-top:6px;
        }
    }
}

// @name Video Iframe
.rte {
    .field, .field-items {
        iframe {
            display: block;
            width: 56rem;
            height: 31.5rem;
            max-width: 100%;
        }
    }
}
