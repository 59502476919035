.list-type-3__picture {
    float: none;
    img {
        margin-right: 0;
        margin-bottom: 1em;
        max-width: none;
        height: auto;
    }
}

.list-type-3__item {
	.link-bloc__context {
		display: block;
	}
}