.home_stage-games-section {
   
   .home-section_heading {
    text-align:center;
      
      .stage-games_btn-more {
         position:static;
      }
   }
   
    .home-section_lg-button {
        margin-top:13px;
        margin-bottom: -2px;
    }
}