.home_text-section_container {
	display: block;
}

.home_text-section_text-block {
   width:100%;
   &:first-child {
      width:100%;
      margin-bottom: 10px;
     
   }
   .form__field-wrapper {
    width: 54%;
    margin-right: 28px;
    margin-bottom:18px;
    display: inline-block;
    margin-bottom: 1.2em;
    input {
      width: 100%;
    }
   }
   .button-2 {
    margin: 0;
    width: 41%;
            padding: 15px 42px 10px 58px;
        vertical-align: baseline;
        &:before {
              position: static;
    display: inline-block;
        vertical-align: -8px;
        margin-right: 11px;
        
        }
   }
    .home-section_heading {
        h2 {
          margin-bottom: 20px;
        }
      }
      .home_text-section_form {
        margin-top: 1px;
      }
      
      fieldset {
        margin-bottom:64px;
      }
}

.home_text-section_item_text {
	    display: flex;
    align-items: center;
    justify-content: space-around;
}

.home_text-section_text-box  {
   padding:0 0 0 70px;
   &:before {
   	left: 52px;
   }
}

.home_text-section_text-col {
  width: 50%;
  &:first-of-type {
    margin-right: 12px;
  }
	ul {
		margin-left: 4px;
    li {
      padding-left: 1.1rem;
      &:before {
        left: -4px;
      }
    }
	}
}
.home_text-section_col-holder {
  margin-bottom: 35px;
}

.home-section {
  &:after,
  &:before {
    display: none !important;
  }
}