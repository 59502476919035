.flash-info {
	padding-top: 15px;
	padding-bottom: 20px;
	&-wrap {
		flex-direction: column;
	}
	&-close {
		top: -10px;
	}
	&-title {
		padding: 0 50px 10px 0;
		margin: 0 0 10px;
		border: none;
		justify-content: flex-start;
		border-bottom: 1px solid #000;
		width: 100%;
	}
}