// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    background: $color-1--2;
    padding: 48px 0;
    color:$color-white;
    .headding__wrapper-title {
        @extend .wrapper-main;
        margin-bottom: 2em;
    }
    .heading__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
            padding-bottom: 0;
        }
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            height: auto;
        }
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-family: $typo-4;
        font-weight: $bold;
        font-size: #{$heading__h1__font-size}em;
        line-height: 1.3;
        color:$color-white;
        letter-spacing:-1.3px;
        margin:0 0 15px;
    }
    h1 + .teaser-2 {
        margin-top: 10px;
        color:$color-white;
    }
    .subtitle {
        $category__font-size: 1.6;
        font-size: #{$category__font-size}em;
        color:$color-black;
        
        > span {
            display:inline-block;
            vertical-align:top;
            position:relative;
            min-width:146px;
            text-align:left;
            padding:7px 25px 7px 25px;
            overflow:hidden;
            z-index:1;
            &:before {
                content:'';
                position:absolute;
                top:0;
                left:-15px;
                right:4px;
                bottom:0;
                background:$color-2--2;
                transform:skew(-13deg, 0deg);
                z-index:-1;
            }
        }
    }
    .subtitle + h1 {
        margin-top:4px;
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid darken($color-3--1, 20%);
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-3--3;
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .heading__wrapper {
.heading__wrapper-figure {
   flex: auto;
}
    .heading__wrap {
        flex-basis: 70%;
       
    }
}
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}

.heading__breadcrumb {
    font-size: 1.4rem;
        color: #727272;
    span {
        position: relative;
        padding-right: 10px;
        &:after {
            position: absolute;
            content: '/';
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
}

.heading-reference {
    font-size: 1.3rem;
        color: #727272;
}