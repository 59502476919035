.home-section_heading {
    justify-content: center;
    margin: 0 0 2rem;

    &--social {
        flex-direction: column;
        align-items: center;
        
        h2 {
            margin-bottom: 3.5rem;
        }
    }
}

.home-section {
    &.angle-section {
        .section-main__wrapper {
            padding-top: 5.2rem;
        }
    }
    
    .section-main__wrapper {
        padding-top: 3.6rem;
    }
    
    //h2,
    //.home-section_title {
    //    //font-size: 2.2rem;
    //    //margin:0 0 1.6rem;
    //
    //    &:before {
    //        left:50%;
    //        margin-left:-32px;
    //        transform:translateX(-100%);
    //    }
    //}
    
    .home-section_lg-button {
        margin-bottom: 6.4rem;
    }
    
    .home-section_lg-button--2 {
        margin-bottom: 0.5rem;
    }
}
