.subpages-menu {
    &__listitems {
        > * {
            flex: 0 1 45.9333%;
        }
    }
    &__picture {
        img {
            max-width: none;
            width: 100%;
        }
    }
}
