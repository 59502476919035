.menu-cross {
    align-items: center;
    flex-direction: column;
    //padding-top: 28px;
    padding-bottom: 1.4rem;

    &::before {
        content: none;
    }

    &::after {
        bottom: 0;
        display: block;
        height: 0.1rem;
        left: 50%;
        top: inherit;
        transform: translateX(-50%);
        width: 9.3rem;
    }

    ul {
        margin: 0;
        max-width: 17rem;
        padding: 0;
        width: 100%;

        &:first-of-type {
            margin: 0 0 2.5rem;
            padding: 0 0 1.3rem;

            &::after {
                bottom: 0;
                height: 0.1rem;
                left: 50%;
                top: inherit;
                transform: translateX(-50%);
                width: 9.3rem;
            }
        }

        li {
            &::before {
                display: inline-block;
                margin-right: 0.3rem;
                position: static;
                vertical-align: -0.4rem;
            }

            a {
                display: inline-block;
            }
        }
    }
}
