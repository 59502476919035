.home_gallery-section {
    $this: &;

    margin: 0 0 10rem;

    &_items {
        display: block;
        height: 49rem;
        position: relative;

        &:hover,
        &:focus {
            #{$this}_title {
                text-decoration: underline;
            }
        }
    }

    &_image {

        @at-root {
            div#{&} {
                min-width: 20rem;
                @include default-icons-absolute-before('\e04b', 5rem, $color-3--2, 50%, inherit, inherit, 50%);

                &::before {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition: opacity ease 0.3s;
                }

                &::after {
                    background: $color-3--1;
                    content: '';
                    display: block;
                    //padding-top: 66.66667%;
                    padding-top: 63%;
                }
            }
        }

        img {
            height: 100%;
            width: 100%;
            display: block;
            font-family: 'object-fit: cover; object-position: center;';
            object-fit: cover;
        }
    }

    &_container {
        background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill-opacity='0.9' fill='%238dc63f' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 400 191' width='400' height='191' %3E%3Cpath d='M0 11.94L400 0.5L400 191L30.29 183.73L0 11.94Z'%3E%3C/path%3E%3C/svg%3E");
        background-size: 100% 100%;
        width: 40rem;
        min-height: 19.1rem;
        position: absolute;
        right: 5.3rem;
        bottom: -2.7rem;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6.3rem 5.4rem 5.5rem 6.1rem;

    }

    &_category {
        align-items: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='none' viewBox='0 0 354 110' width='100' height='31.07'%3E%3Cpath fill='%2341044f' d='M0 13.12L329.44 0L354.03 108.99L29.29 92.88L0 13.12Z'/%3E%3C/svg%3E");
        background-size: 100% 100%;
        color: $color-white;
        display: flex;
        font-family: $typo-4;
        font-size: 1.6rem;
        font-weight: $bold;
        justify-content: center;
        left: -3.8rem;
        letter-spacing: -0.048rem;
        line-height: 1;
        min-height: 5.2rem;
        min-width: 16.8rem;
        padding: 1.8rem 2.3rem 1.8rem 3rem;
        position: absolute;
        text-transform: uppercase;
        top: -1.9rem;
        z-index: 1;
    }

    &_title {
        color: $color-black;
        font-family: $typo-4;
        font-size: 3.4rem;
        font-weight: $normal;
        letter-spacing: -0.102rem;
        line-height: 1;
    }
}

.carrousel1 {
    padding: 0;
    position: relative;

    .listItems {
        .item {
            width: 100%;
            z-index: 10 !important;

            .decor_row {
                &::after {
                    background-color: $color-white;
                    bottom: -2rem;
                    content: '';
                    height: 3rem;
                    left: -0.2rem;
                    position: absolute;
                    transform: rotate(1deg);
                    width: calc(100% + 0.2rem);
                }
            }
        }
    }

    .pagerCarrousel1 {
        display: none;
    }

    .button-reset.buttonStop.modeplay {
        bottom: 3.2rem;
        height: 0.8rem;
        left: 4.3rem;
        position: absolute;
        width: 0.7rem;
        z-index: 10;
        display: none;

        .txt {
            @extend .hidden;
        }

        img {
            vertical-align: top;
        }
    }

    .prevNextCarrousel1 {
        .prevCarrousel1,
        .nextCarrousel1 {
            top: 50%;
            height: 4.5rem;
            left: 3rem;
            position: absolute;
            width: 2.7rem;
            z-index: 11;

            &:hover {
                opacity: 0.85;
            }

            img {
                display: block;
                height: auto;
                width: 100%;
            }
        }

        .nextCarrousel1 {
            left: auto;
            right: 3rem;
        }
    }
}
