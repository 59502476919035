// Newsletter inscription
.newsletter {
    width: 317px;
    padding-left: 22px;
    &__title {
        margin-bottom: .5em;
        margin-top: 0;
        font-size: 1.8rem;
        color: $color-2--2;
        text-transform: uppercase;
        font-family: $typo-4;
    }
    form {
        margin: 0;
    }
    &__fields {
        &-wrapper {
            position: relative;
            margin-bottom: 7px;
            input {
                width: 100%;
                height: 34px;
                padding-left: 13px;
                padding-right: 45px;
                background: transparent;
                border-color: #fff;
                color: #fff;
                font-size: 1.3rem;
                font-family: $typo-5;
                font-weight: normal;
                &::-webkit-input-placeholder {
                    color: #fff;
                }
                &::-moz-placeholder {
                    color: #fff;
                }
                &:-moz-placeholder {
                    color: #fff;
                }
                &:-ms-input-placeholder {
                    color: #fff;
                }
            }
        }
    }
    &__button-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        .button-2 {
            position: relative;
            width: 43px;
            height: 34px;
            background: transparent;
            svg {
                position: absolute;
                width: 17px;
                height: 17px;
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                fill: $color-2--2;
            }
        }
    }
    &__links {
        li {
            display: inline-block;
            margin: 0 15px 0 0 !important;
            padding-left:10px;
            text-transform:uppercase;
            &:before {
                font-size: 10px;
                line-height:1.1;
            }
            &:first-of-type {
                margin-left: 0;
            }
            a {
                color: #fff;
                font-size: 1.2rem;
                &:hover, &:focus {
                    color: $color-2--2;
                }
            }
        }
    }
}