// @name Cross menu
.menu-cross {
    display: flex;
    overflow: hidden;
    padding: 0 3.7rem;
    position: relative;
    text-transform: uppercase;

    &::before,
    &::after {
        position: absolute;
        content: '';
        width: 0.1rem;
        height: 9.3rem;
        background-color: $color-3--7;
        top: 0;
        left: 0;
    }

    &::after {
        right: 1.8rem;
        left: inherit;
    }
    ul {
        min-width: 20rem;

        &:first-of-type {
            margin-right: 3.7rem;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 0.1rem;
                height: 9.3rem;
                background-color: $color-3--7;
                top: 0;
                right: 0;
            }
        }
    }

    li {
        padding: 0.2rem 1.5rem 0.4rem;
        @include default-icons-absolute-before('\e027', 1rem, $color-2--2, 0, inherit, inherit, 0);

        &::before {
            top: 50%;
            transform: translateY(-50%);
        }

        a {
            color: $color-white;
            display: block;
            font-size: 1.4rem;
            line-height: 1.2rem;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $color-2--2;

                span {
                    text-decoration: underline;
                }
            }
        }

        &.active a {
            color: $color-1--2;
            font-weight: $bold;
            text-decoration: none;
        }
    }
}
