// @name Header page
.header {
    background-color: $color-1--2;
    //position: relative;
    //z-index: 99;
    @include angle(after, true, -1deg);

    &::after {
        bottom: -3rem;
    }

    &-wrap {
        align-items: start;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 134rem;
        padding: 7rem 2rem 2rem;
        position: relative;


        //&:after {
        //    background: $color-white;
        //    bottom: -1.7rem;
        //    content: '';
        //    height: 3.3rem;
        //    left: -9999px;
        //    position: absolute;
        //    right: -9999px;
        //    transform: rotate(-1deg);
        //    z-index: -1;
        //}
    }

    &-right-bar {
        display: flex;
        //padding-left: 30px;
    }
}

// @name Top of content
.top-of-content {
    background: $color-1--2;
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    border-bottom: 1px solid rgba($color-3--2, 0.25);
    max-width: 116rem;
    padding: 2rem 0;
}

// @name Main wrapper
.main {
    clear: both;
    width: 100%;
}

// @name Main section
.section-main {

}

.section-wrap {
    display: flex;
    //margin-top: 10rem;

    .column-1 {
        //width: 67%;
        width: 80rem;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .column-2 {
        padding-left: 6rem;
        width: 42rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-bottom: 3em;
    padding-top: 8em;
}

.section-main__wrapper-2 {
    display: flex;
    width: 100%;
    > * {
        vertical-align: top;
    }
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    padding-top: 0.7rem;

    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    @include angle(before, true, 1deg);
    background: $color-1--2;
    &__wrapper {
        @extend .wrapper-main;
        color: $color-white;
        max-width: 124em;
        padding: 1rem 1rem 5.4rem;
    }
    &__wrapper-2 {
        display: flex;
        margin: 1.6rem 0;
        width: 100%;
    }
    a {
        font-family: $typo-4;
        font-weight: $light;
    }
}

.page {
    overflow: hidden;
    position: relative;
    top: -0.1rem;
}
