// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    // img {
    //     max-width: 100%;
    //     height: auto;
    //     margin-bottom: 2em;
    // }
    // .bloc-contact__title {
    //     $bloc-contact__title__font-size: 1.6;
    //     font-family: $typo-3;
    //     font-weight: $bold;
    //     font-size: #{$bloc-contact__title__font-size}em;
    //     margin: 0 0 em(1.5, $bloc-contact__title__font-size);
    //     color: $color-black;
    //     a {
    //         color: $color-black;
    //         &:hover, &:focus {
    //             color: $color-1--2;
    //         }
    //     }
    // }
    // .list-infos {

    // }
    
    .bloc-contact__item {
        font-family:$typo-4;
        overflow:hidden;
        
        a {
            font-size:1.5rem;
            line-height:1.2;
            color:$color-white;
            font-weight:$light;
            text-decoration:underline;
            &:hover,
            &:focus {
                text-decoration:none;
            }
        }
    }
    
    .bloc-contact__wrapper {
        position:relative;
        padding:20px 22px 32px;
        &:before {
            content:'';
            position:absolute;
            left: -13px;
            top: -8px;
            right: 14px;
            bottom: 6px;
            transform: rotate(2.5deg) skew(-4deg, 0deg);
            background:$color-1--2;
            z-index:-1;
        }
    }
    
    .list-infos__wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        color:$color-white;
        
        > * {
            flex: inherit;
        }
        
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }
    
    .bloc-contact_list {
        margin:0 0 26px;
        padding:0;
        list-style:none;
        font:$light 1.5rem/1.2 $typo-4;
        color:$color-white;
        
        li {
            margin:0 0 5px;
        }
        
        strong {
            color: $bloc-contact-color;
        }
    }
    
    .list-infos {
        margin:0 0 28px;
        
        &__item {
            font-family:$typo-4;
            &:before {
                color: $bloc-contact-color;
            }
            
            a {
                font-weight:$normal;
                text-decoration:none;
                &:hover,
                &:focus {
                    text-decoration:underline;
                }
            }
        }
    }
    
    .button-2 {
        margin-top: em(1, $button-2__font-size);
        &:before {
            content: "\e02a";
        }
    }
}
