.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 6em 0 5em 0;
    &:last-child {
        margin-bottom:0;
    }
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        height: 0;
        transition: max-height ease .2s;
    }
    &.ddm--active {
        
        .ddm__sub-level {
            padding:31px 20px 44px 30px;
            max-height: 100%;
            height:auto;
            transition: height ease-in-out 1s;
        }
    }
}

.ddm__content {
    
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 2.4rem;
        font-family: $typo-4;
        font-weight: $normal;
        background: $color-2--2;
        padding: 7px 55px 8px 10px;
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 45px;
            background: #7fb239;
            z-index: 1;
        }
        @include default-icons-absolute-before('\e08a', 18px, $color-white, 50%, 14px, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    
    &.ddm--active {
        .ddm__button {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e023";
                color: $color-white;
            }
            &:after {
                background: #24002c;
            }
        }
    }
    
    h4 {
        margin:0 0 14px;
    }
    
    h5 {
        font-size:1.7rem;
        font-family:$typo-4;
        font-weight:$bold;
        margin:0 0 15px;
    }
    
    p {
        margin:0 0 18px;
    }
    
    .ddm__sub-level {
        
        > ul,
        > ol {
            padding:0;
            margin:15px 0 -8px;
            font-size:1.4rem;
            
            > li {
                margin:0 0 8px;
                padding-left:7px;
            }
            
            li {
                &:before {
                    left:-6px;
                }
            }
        }
        
        > ol {
            padding-left:10px;
        }
        
        > ul + ol,
        > ol + ul {
            margin-top:25px;
        }
    }
}