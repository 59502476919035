// @name Go so far
// @description Sections content below the content

.go-so-far {
    padding: 62px 0;
    
	&__wrapper {
	    display:block;
	    width:100%;
	    margin:0 0 -17px;
	    
	    > * {
	    	margin:0 0 33px;
	    	&:last-child {
	    		margin-bottom:0;
	    	}
	    }
	}

	&__title {
		margin:0 45px 20px;
		font-size:2.5rem;
		&:before {
			left:0;
		}
	}
}
