// @name Main menu
.main-menu {
    display: flex;
    font-family: $typo-4;
    margin-top: 1.6rem;

    > ul {
        align-items: center;
        display: flex;

        > li {
            display: inline-block;
            //width: calc(100% / 3);

            &.focus,
            &.hover {
                > a {
                    color: $color-white;
                    z-index: 101;

                    &:before {
                        background: $color-white;
                        width: calc(100% - 4.4rem);
                    }
                }

                > ul {
                    visibility: visible;
                }
            }

            &.has-drop.focus,
            &.has-drop.hover {
                > a {
                    //outline: 0.1rem dashed $color-2--2
                }

                .decor {
                    position: relative;

                    &::after {
                        background-color: $color-2--2;
                        bottom: -9rem;
                        content: '';
                        height: 4.4rem;
                        left: 0;
                        position: absolute;
                        transform: rotate(-27deg) skew(-4deg);
                        width: 7.1rem;
                    }
                }
            }

            > a {
                align-items: center;
                color: $color-2--2;
                display: flex;
                font-family: $typo-4;
                font-size: 1.8rem;
                font-weight: $bold;
                letter-spacing: -0.054rem;
                padding: 0 2.2rem;
                position: relative;
                text-decoration: none;
                text-transform: uppercase;

                &:before {
                    background: $color-2--2;
                    content: '';
                    height: 0.1rem;
                    left: 2.2rem;
                    position: absolute;
                    top: -1rem;
                    transition: all 0.3s linear;
                    width: 3rem;
                }

                &:hover,
                &:focus {
                    color: $color-white;

                    &:before {
                        background: $color-white;
                        width: calc(100% - 4.4rem);
                    }
                }
            }

            > ul {
                background-color: $color-2--2;
                box-shadow: 0.2rem 1.3rem 1.3rem rgba($color-black, 0.4);
                left: 50%;
                overflow: hidden;
                padding: 4rem 6.5rem 5rem;
                position: absolute;
                top: calc(100% - 1rem);
                transform: translate(-50%, 0);
                visibility: hidden;
                //width: 122.1rem;
                //min-width: 80rem;
                width: 65%;
                z-index: 100;
                min-height: 22rem;

                > li {
                    align-items: flex-start;
                    display: flex;

                    > ul {
                        //display: flex;
                        //flex-direction: column;
                        position: relative;
                        text-align: right;
                        margin-left: 2rem;
                        max-width: 35rem;
                        min-width: 25rem;
                        //width: 40%;
                        //width: 40%;
                        z-index: 1;

                        &:after {
                            background: $color-1--3;
                            bottom: -50rem;
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 0.6rem;
                            z-index: -1;
                        }

                        > li {
                            //align-self: flex-end;
                            padding: 0 0.6rem 0 0;

                            &:hover {
                                > ul {
                                    display: block;
                                }
                            }

                            &.not-focus {
                                > ul {
                                    display: none;
                                }
                            }

                            &.focus {
                                > a {
                                    background: $color-1--3;
                                    color: $color-white;
                                }

                                > ul {
                                    display: block;
                                }
                            }

                            > a {
                                color: $color-black;
                                display: inline-block;
                                font-size: 1.6rem;
                                font-weight: $bold;
                                letter-spacing: -0.048rem;
                                padding: 0.5rem 3rem;
                                text-decoration: none;
                                text-transform: uppercase;
                                vertical-align: top;

                                &:hover,
                                &:focus {
                                    background: $color-1--3;
                                    color: $color-white;
                                }
                            }

                            > ul {
                                display: none;
                                left: 100%;
                                padding: 0 0 0 3.3rem;
                                position: absolute;
                                text-align: left;
                                top: 0;
                                width: 30rem;

                                a {
                                    color: $color-1--2;
                                    display: inline-block;
                                    font-size: 1.6rem;
                                    font-weight: $light;
                                    line-height: 3rem;
                                    padding: 0 1.2rem 0 2.4rem;
                                    position: relative;
                                    text-decoration: none;
                                    vertical-align: top;

                                    &:after {
                                        content: '\e017';
                                        font-family: 'icons-default';
                                        font-size: 1.2rem;
                                        font-weight: $normal;
                                        left: 1rem;
                                        line-height: 1;
                                        position: absolute;
                                        top: 1rem;
                                    }

                                    &:hover,
                                    &:focus {
                                        background-color: $color-1--2;
                                        color: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-title {
        color: $color-1--2;
        font-family: $typo-4;
        font-size: 3rem;
        font-weight: $light;
        height: 2.7rem;
        letter-spacing: -0.09rem;
        line-height: 1;
        margin-top: 1.6rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        min-width: 12.3rem;
        max-width: 20rem;

        &::before {
            background: $color-1--2;
            content: '';
            height: 0.1rem;
            left: 0;
            position: absolute;
            top: -1rem;
            width: 4.6rem;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &-close {
        height: 40px;
        overflow: hidden;
        position: absolute;
        right: 20px;
        top: 14px;
        width: 40px;

        &:after,
        &:before {
            background: #768c44;
            content: '';
            height: 22px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 2px;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:focus {
            outline: 1px dashed #000;
        }
    }
}
