// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-kiosk {
    
    .bloc-kiosk__item {
        display:flex;
        align-items:center;
    }
    
    .column-bloc__wrapper {
        width:42%;
        justify-content:center;
    }
}

.bloc-kiosk__picture {
    width:29%;
    
    img {
        width:100%;
        max-width:none;
    }
}

.bloc-kiosk__item {
    
    .link-view {
        width:29%;
    }
}
