.top-of-content {
    display: none;
}

.header {
    &::after {
        bottom: -3rem;
        transform: skewY(-2deg);
    }

    &-wrap {
        padding: 1rem;
    }
}

// @name Main section wrappers
.section-main__wrapper {
    padding: 4.3rem 1rem;
    width: 100%;
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

.section-main__content {
    margin: 0 0 5rem;
}

.link-bloc__context {
    display: block;

    > * {
        &:first-child {
            width: 100%;
        }
    }
}

.section-main__aside {
    padding-top: 0.2rem;
}

.column-bloc__wrapper {
    display: block;
}

// @name Footer
.footer {
    .footer__wrapper-2 {
        //display: block;
        margin: 0;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
            width: 100%;
        }
    }
    &__wrapper {
        padding-bottom: 0.7rem;
        padding-top: 3rem;
    }
}

.header-right-bar {
    padding-left: 1.1rem;
}
