.ddm.ddm__content.ddm__location {
  margin:36px 0 0;
  background-color: #f1f1f1;
  &.ddm--active {
    
    .ddm__sub-level {
      height:auto;
      padding:20px;
    }
  }
    
    .ddm__button {
        cursor:pointer;
        pointer-events:auto;
        height:70px;
        font-size:2.5rem;
        margin:0;
        padding:0 15px;
        text-align:center;
        color:$color-2--2;
        background:$color-1--2;
        &:before {
          position:static;
          display:inline-block;
          vertical-align:middle;
          content: "\e04d";
          color:$color-2--2;
          font-size:30px;
          margin:11px 6px -15px -8px;
        }
    }
    
    .divider {
        display:none;
    }
    
    .ddm__sub-level {
      height:0;
    }
    
    .ddm__title {
        &:before {
            display:none;
        }
    }
}

.location-block_map-box {
  display:none;
}

.location-block_info-box {
  display:block;
  width:100%;
  padding:0;
  min-height:0;
}