.header-logo {
    width: 42rem;

    .logo-left {
        font-size: 1.5rem;
        margin-right: 2.9rem;

        strong {
            font-size: 2.2rem;

            span {
                font-size: 1.6rem;
            }
        }

        span {
            font-size: 1.1rem;
        }
    }
}
