.home_text-section_container {
   display:flex;
   justify-content:space-between;
   margin-bottom: -80px;

}

.home_text-section_text-block {
   width:32%;
   legend {
     font-family: $typo-4;
     font-weight: $normal;
     font-size: 1.6rem;
   }
   label {
    font-size: 1.6rem;
   }
   input {
      font-size: 1.6rem;
      width: 89%;
      height: 45px;
      border-color:#fff;
 
      &::-webkit-input-placeholder {
        color: #d1d1d1;
      }
      &::-moz-placeholder { 
        color: #d1d1d1;
      }
      &:-ms-input-placeholder { 
        color: #d1d1d1;
      }
      &:-moz-placeholder { 
        color: #d1d1d1;
      }
   }
   .button-2 {
          padding: 21px 42px 21px 71px;
      margin-bottom: 25px;
      font-size: 1.9rem;
      &:before {
         left: 46px;
      }
   }
   &:first-child {
      width:63%;
   }
   .home-section_heading {
      margin-bottom: 12px;
   }
   .home_text-section_form {
      margin: 0;
   }
   .form__field-wrapper {
      margin-top: 0.5em;
   }
}

.home_text-section_col-holder {
   display:flex;
}

.home_text-section_text-col {
   width:46%;
   &:first-of-type {
      margin-right: 25px;
   } 
   
   ul {
      font-size:1.4rem;
      margin: 2px 0px 10px 0px;
      
      li {
         padding-bottom:11px;
         margin-bottom:13px;
             padding-left: 1.5rem;
         border-bottom:1px solid #c9c9c9;
         &:before {
            left: 1px;
         }
         &:last-child {
            margin-bottom:0;
         }
      }
      
      a {
         text-decoration:none;
         font-size: 1.4rem;
         font-weight: 300;
         color:$color-black;
         &:hover,
         &:focus {
            color:$color-2--2;
         }
      }
   }
}

.home_text-section_form-list {
   margin:0 0 -10px;
   padding:0;
   list-style:none;
   
   li {
      display:inline-block;
      vertical-align:top;
      margin:0 14px 10px 0;
   }
   
   a {
      position:relative;
      display:inline-block;
      padding-left: 15px;
      vertical-align:top;
      text-transform: uppercase; 
      font-family: $typo-4;
      font-weight: 700;
      font-size: 1.2rem;
      color: #000;
      &:before {
         position: absolute;
         content: '\e03d';
         left: 0;
         top: 50%;
         transform: translateY(-50%);
             font-family: "icons-default";
             color: #8dc63f;
             font-size: 1rem;
      }

      
   }
   .refuse {
      &:before {
         content: '\e005';
      }
   }
}