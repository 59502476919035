// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
}

.heading {
  padding:50px 0;
   
   .subtitle + h1 {
      margin-top:9px;
   }
   
   h1 {
      font-size: 4.5rem;
      line-height:1.1;
      margin:0 0 18px;
   }
   
   .subtitle {
      
      > span {
         padding-left:21px;
      }
   } 
   &--single-directory {
    .heading__picture {
      img {
        max-width: none;
      }
    }
   }
}
