.stage-gallery {
   
   .listItems {
      margin:0;
   }
   
   &_text-box {
       position: static;
       background: none;
       font-size: 1.6rem;
       padding: 16px 0 18px;
       
       .button-1 {
         padding:9px 8px;
       }
   }
   
   &_title {
      margin:0 0 22px;
   }
   
   p {
      margin:0 0 18px;
   }
   
   .prevNextCaroussel3 {
      
      .prevCaroussel3,
      .nextCaroussel3 {
        margin-top:-23px;
      }
      
      .prevCaroussel3 {
         left:-38px;
      }
      
      .nextCaroussel3 {
         right:-34px;
      }
   }
}
