.ddm__home_affiche {
    display: flex;
    flex-wrap: wrap;
    margin: -15px -5px -5px -5px;
    &.ddm--active {

        .ddm__sub-level {
            padding: 0;
        }
    }

    .ddm__title {
        margin: 0 5px 0 0;

        button {
            background: url(../Images/bg/bg-ddm-button.svg) no-repeat;
            background-size: cover;
            color: $color-white;
            cursor: default;
            font: $bold 1.6rem/1.2 $typo-4;
            height: 62px;
            min-width: 147px;
            outline: none;
            padding: 0 5px !important;
            pointer-events: none;
            text-align: center;
            text-transform: uppercase;
            &:before,
            &:after {
                display: none;
            }
        }
    }

    .ddm__sub-level {
        display: block;
        height: auto;
        overflow: visible;
        padding: 0;
        position: static;
        z-index: 1;
    }

    .home_affiche-section_menu {
        display: flex;
        flex-wrap: wrap;
        font: $bold 1.6rem/1.2 $typo-4;
        list-style: none;
        margin: 0 -2px -5px 4px;
        padding: 0;
        text-transform: uppercase;

        li {
            margin: 0 8px 5px 0;
            padding: 0;
            &.active {

                a {
                    background: none;
                    color: #fff;
                    cursor: default;
                    outline: none;
                    &:after {
                        height: 100%;
                    }
                }
            }
        }
    }

    a {
        color: #525252;
        display: block;
        min-width: 157px;
        padding: 19px 5px;
        position: relative;
        text-align: center;
        z-index: 1;
        &:after {
            background: $color-2--2;
            bottom: 0;
            content: '';
            height: 3px;
            left: 0;
            position: absolute;
            right: 0;
            transition: height 0.3s ease;
            z-index: -1;
        }
        &:hover,
        &:focus {
            background: none;
            color: #fff;
            text-decoration: none;
            &:after {
                height: 100%;
            }
        }
    }
}

.home_affiche-section {

    .section-main__wrapper {
        padding-top: 6em;
    }
}

.home_affiche-section_item-block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -16px;
    padding: 0 10px 0 19px;
}

.home_affiche_item {
    margin: 0 0 70px;
    padding: 0 20px;
    position: relative;
    width: 33.33%;

    img {
        height: auto;
        width: 100%;
    }

    .date-1 {
        margin-top: -46px;
        position: relative;
        z-index: 1;

        span:first-child + time {
            margin-left: 0;
        }

        time {
            margin: 0;
            width: auto;
        }

        .month {
            font-size: 1.6rem;
        }
    }

    .category_event {
        margin: -24px 0 0 -8px;
    }
}

.affiche_item_title {
    color: $color-black;
    font-family: $typo-4;
    font-size: 2.4rem;
    font-weight: $bold;
    margin: 0 0 11px;
    padding: 0 0 13px;
    position: relative;
    text-align: center;
    &:after {
        background: $color-2--2;
        bottom: 0;
        content: '';
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 220px;
    }

    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-2--2;
            text-decoration: none;
        }
    }
}

.affiche_item_info {
    font-size: 1.3rem;
    margin: 0 0 30px;
    text-align: center;

    strong {
        display: block;
        font-size: 1.5rem;
        font-weight: $bold;
        text-transform: uppercase;
    }
}

.affiche_item_btn-holder {

    > a {
        font-size: 1.4rem;
        width: 43%;
    }
}
