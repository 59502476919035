.home-news {
    margin: 11rem 0 6rem;

    &__wrap {
        margin: 0;
    }

    &__item {
        flex-basis: 100%;
        margin: 0 0 3.8rem;

        &:nth-last-child(-n+2) {
            margin-bottom: 3.8rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        margin: 4rem 0 1.8rem;
    }
}
