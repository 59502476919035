.scenes-gallery {
   padding: 0 45px;
   
   .listItems {
      
      .item {
         
         a {
            max-width: 127px;
         }
      }
   }
   
   &-ico {
      padding:0 0 7px;
      margin:0 0 14px;
      height:86px;
   }
   
   .prevNextCaroussel3 {
      margin: 0;
      
      .prevCaroussel3,
      .nextCaroussel3 {
         top:41px;
      }
   }
}