.location {
    margin: 6rem 0;

    &__information {
        padding-top: 0;
    }

    &__map {
        margin-left: -4rem;
        max-width: 40rem;

        svg {
            display: block;
            height: 29rem;
            width: 100%;
        }
    }

    &__list {
        padding: 0 0 0 2rem;
    }
}
