// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
    
    .bloc-contact__wrapper {
        display:block;
        padding: 21px 20px 37px;
        &:before {
            left: -13px;
            top: -8px;
            right: 16px;
            bottom: 10px;
            transform: rotate(2.8deg) skew(-4deg, 0deg);
        }
    }
    
    .bloc-contact_holder {
        width:100%;
        text-align:left;
    }
    
    .bloc-contact_list {
      max-width:100%;
      margin:0 0 27px;
    }
    
    .list-infos {
        margin: 0 0 20px;
        &__item {
            padding-left:16px;
        }
    }
}