// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    .button-2 {
        padding:9px 14px;
    }
}

.tools__listitems {
    margin: -2px -4px 2px 0;
}