.newsletter {
    margin-top: 5px !important;
    clear: both;
    padding: 0;
    text-align: center;
    form {
        margin-bottom: 13px;
    }
    &__fields-wrapper {
        max-width: 280px;
        margin: 0 auto;
    }
}
