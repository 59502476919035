// @name site informations
.site-infos {
    width: 435px;
    margin-right: 24px;
    font-family: $typo-5;
    &-links {
        float: left;
        margin-right: 15px;
        margin-top:9px;

        li > a {
            display: inline-block;
            vertical-align: top;
            max-width: 100%;
        }
    }
    .site-infos__wrapper {
        overflow: hidden;
        font-style: normal;
        margin-top:4px;
    }
    p {
        strong {
            color: $color-2--2;
            text-transform: uppercase;
            font-family: $typo-4;
        }
    }
    p,
    ul {
        font-size: 1.3rem;
        margin: 0 8px 5px 0;
        &:first-child {
            margin-top: 0;
        }
    }
    ul {
        > li {
            margin: 0 0 0.3em;
        }
    }
    a {
        color: #fff;
        font-weight: normal;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    &_content {
        p {
               display: inline-block;
        }
        a {
            &:before {
                color: #fff;
            }
        }
        .fax {
            pointer-events: none;
            cursor: default;
          &:before {
              content: '\e09f';
          }
        }
        .email {
            text-decoration: underline;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.tel,
.fax {
    position: relative;
    padding-left: 18px;
    text-decoration: none;
    &:before {
        font-family: "icons-default";
        font-size: 0.75em;
        color: $color-2--2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: inherit;
        bottom: inherit;
        left: 0em;
        content: "\e02b";
        line-height: 1;
        font-size: 1.2rem;
    }
    &:hover,
    &:focus {
        color: $color-2--2;
    }
}
