.go-to-top {
    margin-top: -68px;
    display: block;
    float: right;
    right: 4px;
    bottom: 4px;
    z-index: 900;
    
    p {
        text-align: right;
    }
    
    a {
        padding: 14px 13px 14px 30px;
        border: 1px solid $color-2--2;
        background:$color-white;
        &:before {
            content: "\e025";
            left: 13px;
        }
    }
}
