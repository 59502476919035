.mob-nav {
    background-color: $color-white;
    display: block;
    left: 100%;
    position: absolute;
    top: 0;
    transform: translate(0, 0);
    transition: transform 0.3s linear;
    width: 100%;
    z-index: 999;

    &-opener {
        background-color: $color-white;
        border: 0.1rem solid $color-white;
        display: block;
        float: left;
        height: 4.9rem;
        overflow: hidden;
        position: relative;
        transition: all ease .3s;
        width: 4.9rem;

        img {
            display: none;
        }

        &-icons {
            background-color: $color-1--2;
            height: 0.1rem;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 1.6rem;

            &::after,
            &::before {
                background-color: $color-1--2;
                content: '';
                height: 0.1rem;
                left: 0;
                position: absolute;
                top: 0.6rem;
                width: 100%;
            }

            &::before {
                top: -0.6rem;
            }
        }

        &:hover,
        &:focus {
            border-color: $color-1--2;
        }
    }

    .search-box {
        display: none;
    }

    .language {
        display: flex;
        justify-content: center;
        padding-bottom: 2.1rem;
        padding-top: 2.5rem;

        li {
            float: none;

            &:not(:first-child) {
                &:after {
                    height: 4.7rem;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}

.responsiveMenuActive {
    .mob-nav {
        transform: translate(-100%, 0);
    }

    .responsiveMenuOverlay {
        display: block;
        opacity: 1;
        z-index: 990;
    }
}

.responsiveMenuOverlay {
    background: rgba($color-black, 0.6);
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: -1;
}

.responsiveMenu {
    .top-responsiveMenu {
        background: $color-black;
        overflow: hidden;
        padding: 1.5rem;

        .closeResponsiveMenu {
            color: $color-white;
            float: left;
            font-family: $typo-4;
            font-size: 1.9rem;
            font-weight: $bold;
            height: 4rem;
            line-height: 1.2;
            padding: 0 1rem 0 3rem;
            position: relative;
            text-transform: uppercase;

            img {
                display: none;
            }

            &-left {
                &__icons {
                    background-color: $color-2--2;
                    height: 0.1rem;
                    left: 0.5rem;
                    position: absolute;
                    top: 50%;
                    width: 1.6rem;

                    &::after,
                    &::before {
                        background-color: $color-2--2;
                        content: '';
                        height: 0.1rem;
                        left: 0;
                        position: absolute;
                        top: 0.6rem;
                        width: 100%;
                    }

                    &::before {
                        top: -0.6rem;
                    }
                }
            }

            &-right {
                float: right;
                padding: 0;
                width: 4rem;

                &::after {
                    color: $color-2--2;
                    content: '\e022';
                    font-family: 'icons-default';
                    font-size: 2.6rem;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .main-menu {
        &-close {
            display: none;
        }
    }
}

.mobile-menu {
    font-family: $typo-4;

    > ul {
        border-bottom: 0.1rem solid rgba($color-black, 0.2);

        > li {
            border-top: 0.1rem solid rgba($color-black, 0.2);

            .menu {
                display: none;
            }

            > a {
                align-items: center;
                color: $color-black;
                display: flex;
                font-size: 1.9rem;
                font-weight: $bold;
                height: 8.8rem;
                line-height: 1.2;
                padding: 2rem 2.5rem 2rem;
                text-decoration: none;
                text-transform: uppercase;
                width: 100%;
            }

            &.display {
                border-color: $color-3--5;

                .title {
                    background: $color-1--2;

                    &::after {
                        background-color: $color-1--4;
                        bottom: 0;
                        content: '';
                        height: 1.1rem;
                        left: 10px;
                        position: absolute;
                        right: 10px;
                    }

                    a {
                        color: $color-2--2;

                        &::before {
                            background: $color-2--2;
                        }
                    }

                    .buttonLvl1 {
                        border-left-color: rgba($color-white, 0.2);

                        &::before {
                            background: $color-2--2;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }

                > ul {
                    display: block;
                    > li {
                        > ul {
                            display: block;
                        }
                    }
                }
            }

            > ul {
                background-color: $color-1--2;
                position: relative;

                &:after {
                    border-left: 1.4rem solid transparent;
                    border-right: 1.4rem solid transparent;
                    border-top: 1.4rem solid $color-1--4;
                    content: '';
                    left: 2.8rem;
                    position: absolute;
                    top: 0;
                }
                > li {
                    > a {
                        display: none;
                    }
                    > ul {
                        padding: 1.4rem 1rem;

                        > li {
                            > a {
                                color: $color-white;
                                display: block;
                                font-size: 1.6rem;
                                font-weight: $bold;
                                line-height: 1.2;
                                padding: 1.7rem 2.2rem;
                                text-transform: uppercase;
                            }
                            &.display {
                                .drop-title-sublevel {
                                    .buttonLvl3 {
                                        background: $color-2--2;

                                        &::before {
                                            background: $color-1--2;
                                        }

                                        &::after {
                                            display: none;
                                        }
                                    }
                                }

                                > ul {
                                    display: block;
                                }
                            }

                            > ul {
                                padding: 0 2.2rem;

                                li {
                                    padding: 0 0 0.2rem;
                                }
                                a {
                                    color: $color-white;
                                    display: block;
                                    font-size: 1.6rem;
                                    font-weight: $light;
                                    line-height: 1.2;
                                    padding: 0.4rem 0 0.4rem 1.5rem;
                                    position: relative;

                                    &::before {
                                        content: '\e017';
                                        font-family: 'icons-default';
                                        font-size: 1.3rem;
                                        left: 0;
                                        line-height: 1;
                                        position: absolute;
                                        top: 0.7rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .title {
        padding-right: 6.4rem;
        position: relative;

        a {
            align-items: center;
            color: $color-1--2;
            display: flex;
            font-size: 1.8rem;
            font-weight: $bold;
            height: 8.8rem;
            line-height: 1.2;
            padding: 2rem 2.5rem 2rem;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;

            &::before {
                background: $color-1--2;
                content: '';
                height: 0.1rem;
                left: 2.5rem;
                position: absolute;
                top: 2.6rem;
                width: 3rem;
            }
        }

        .buttonLvl1 {
            border-left: 0.1rem solid rgba($color-black, 0.2);
            height: 3.8rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 6.4rem;

            &::after,
            &::before {
                background: $color-2--2;
                content: '';
                height: 0.1rem;
                left: 50%;
                margin-left: -0.7rem;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 1.1rem;
            }

            &::after {
                height: 1.1rem;
                width: 0.1rem;
            }
        }
    }

    .drop-title-sublevel {
        padding: 0 4.5rem 0 0;
        position: relative;

        a {
            color: $color-white;
            display: block;
            font-size: 1.6rem;
            font-weight: $bold;
            line-height: 1.2;
            padding: 1.7rem 2.2rem;
            text-transform: uppercase;
        }

        .buttonLvl3 {
            border: 0.1rem solid $color-2--2;
            height: 2.8rem;
            position: absolute;
            right: 1.4rem;
            top: 50%;
            transform: translate(0, -50%);
            width: 2.8rem;

            &::after,
            &::before {
                background: $color-2--2;
                content: '';
                height: 0.1rem;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 1.1rem;
            }

            &::after {
                height: 1.1rem;
                width: 0.1rem;
            }
        }
    }
}
