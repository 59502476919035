// Galerie classique (avec vignettes) + Galerie album

// Galerie classique (avec vignettes) + Galerie album
.classical-gallery {
    position: relative;
    max-width: none;
    margin:57px 14px 68px;
}
.slider-galerie {
    img {
        width: 100%;
    }
    .infos-img {
        padding: 6px 10px 10px 11px;
    }
}