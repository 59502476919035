.ddm__home_affiche {
   display:block;
   margin:0;
   
   &.ddm--active {
      .ddm__sub-level {
         display:block;
      }
   }
   
   .ddm__title {
      margin:0;
      
      button {
         display:block;
         width:100%;
         min-width:0;
         height:45px;
         padding:0 10px !important;
         font-size:1.6rem;
         cursor:pointer;
         pointer-events:auto;
         background:$color-1--2;
         
         span {
            position:relative;
            display:inline-block;
            vertical-align:top;
            margin:0 50px 0 0;
            
            &:before {
               content:'';
               position:absolute;
               right:-11px;
               top:-4px;
               width: 1px;
               height: 27px;
               opacity: 0.3;
               background-color: white;
            }
            &:after {
               content: "\e018";
               position:absolute;
               right:-36px;
               top:50%;
               transform:translateY(-50%);
               color:$color-2--2;
               font-family: icons-default;
               font-size: 11px;
               line-height: 1;
            }
         }
      }
   }
   
   .ddm__sub-level {
      display:none;
      position:static;
   }
   
   .home_affiche-section_menu {
      display:block;
      margin:5px 0 0;
      
      li {
         margin:0 0 5px;
      }
      
      a {
         padding:13px 10px;
      }
   }
}

.home_affiche-section_item-block {
   display:block;
   margin:0;
}

.home_affiche_item {
   width:100%;
   padding:0;
   margin:0 0 39px;
   
   .category_event {
      left:-2px;
      
      span {
         min-width:105px;
         width:auto;
      }
   }
   
   .date-1 {
      margin-bottom:16px;
   }
}

.affiche_item_title {
   font-size:2rem;
   margin: 0 0 12px;
   padding: 0 0 12px;
}

.affiche_item_info {
    margin: 0 0 13px;
}

.affiche_item_btn-holder {
   
   > a {
      display:block;
      width:51%;
      margin:0 auto;
      & + a {
         margin-top:8px;
      }
   }
}