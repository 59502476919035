// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-kiosk {
    
    img {
        max-width: 100%;
        height: auto;
        margin:0 0 15px;
    }
    
    .bloc-kiosk__title {
        $bloc-event__title__font-size: 2.4;
        font-family: $typo-4;
        font-weight: $bold;
        font-size: #{$bloc-event__title__font-size}rem;
        margin: 0 0 13px;
        color: $color-black;
        letter-spacing:0.5px;
        
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-2--2;
                text-decoration:none;
            }
        }
    }
    
    .link-view {
        text-align:left;
    }
}
