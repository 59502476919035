.home_affiche-section {

    .section-main__wrapper {
        padding-bottom: 0;
        padding-top: 3.6em;
    }
}

.ddm__home_affiche {
    flex-wrap: nowrap;
    margin: 0 0 0 -9px;

    .home_affiche-section_menu {
        margin-right: -10px;
    }

    a {
        min-width: 139px;
    }
}

.home_affiche-section_item-block {
    margin: 0 -11px -5px;
    padding: 0;
}

.home_affiche_item {
    margin: 0 0 49px;
    padding: 0 11px;
    width: 33.334%;

    .category_event {
        margin: -20px 0 0 -8px;
    }

    .date-1 {
        margin-top: -42px;
    }
}

.affiche_item_title {
    padding: 0 0 11px;
}

.affiche_item_btn-holder {
    > a {
        margin-bottom: 0.4rem;
        width: auto;
    }
}

