// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    margin: 0 -1em;
    padding: 0 1em;
    &:before, &:after {
        width: 0;
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
.column-bloc {
    margin: 0 auto 6.1rem;
    padding-left: 0;
    width: 27rem;
}

.wrapper-main {
    padding: 0 1em;
}

.wrapper-1220 {
    padding: 0 1rem;
}
