.plans {
    background: #f1f1f1;
    .section-main__wrapper {
        padding-top: 1.6em;
        padding-bottom: 3.5rem;
    }
    &-wrap {
        overflow: hidden;
        margin: 0 0 9px;
    }
    &-carousel {
        width: 360px;
        float: left;
        margin: 0 35px 0 0;
        min-height: 1px;
        position: relative;
        z-index: 1;
    }
    &-description {
        overflow: hidden;
    }
    &-info {
        display: table;
        width: 100%;
        font-size: 1.6rem;
        &-row {
            display: table-row;
            > div {
                display: table-cell;
                vertical-align: middle;
                padding: 6px 10px 5px 0;
            }
        }
        a {
            color: #000;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        &-title {
         
            font-size: 1.6rem;
            color: #000;
            width: 179px;
        }
    }
    .classical-gallery {
        margin: 0em 0 2em;
    }
    .slider-galerie {
        margin: 0 0 19px;
        a {
            padding: 0;
            img {
                width: 100%;
                height: auto;
                position: static;
                transform: none;
            }
        }
    }
    .carousel-galerie__pager {
        display: none !important;
    }
    .carousel-galerie {
        width: 100% !important;
        .carousel-galerie__thumb {
            margin-left: 0;
        }
    }
    .carousel-galerie__thumb {
        white-space: nowrap;
        width: 100% !important;
        margin: 3px 0 0;
    }
    .carousel-galerie__item {
        width: 29.5%;
        display: inline-block;
        vertical-align: top;
        margin: 0 10px !important;
        &:first-child {
            margin-left: 0 !important;
        }
        button {
            background: #de981b;
            img {
                width: 100%;
                height: auto;
                opacity: 0.4;
            }
        }
    }
    .cycle-pager-active {
        button {
            img {
                opacity: 1;
            }
        }
    }
    .classical-gallery__nb-items {
        display: none;
    }
    &-text {
        margin: 0 0 67px;
        .sub-title {
            display: block;
            font-size: 1.8rem;
            margin: 0 0 26px;
        }
        p {
            margin: 0 0 29px;
        }
    }
}
