.top-of-content__wrapper {
    margin: 0 2rem;
    padding: 2.1rem 0 2rem;
    width: auto;
}

.header {
    &-wrap {
        padding-bottom: 0;
        padding-top: 2.1rem;
        position: relative;

        //&:after {
        //    background: $color-white;
        //    bottom: -1.2rem;
        //    content: '';
        //    height: 3rem;
        //    left: 0;
        //    position: absolute;
        //    transform: rotate(-1deg);
        //    width: 100%;
        //}

        .pane-gtranslate-gtranslate {
            display: none;
        }
    }

    //&:before {
    //    background-size: 515px 60px;
    //    bottom: -18px;
    //    height: 60px;
    //    width: 515px;
    //}
    //
    //&:after {
    //    background-size: 187px 34px;
    //    bottom: -8px;
    //    height: 34px;
    //    width: 187px;
    //}
}

.section-wrap {
    flex-direction: column;

    .column-1 {
        width: 100%;
        max-width: 100%;
    }

    .column-2 {
        padding-left: 0;
        width: 100%;
    }
}

.section-main__wrapper-2 {
    display: block;
    > * {
        display: block;
    }
}

.section-main__content {
    margin: 0 0 6.5rem;
}

.footer {
    &:before {
        display: none;
    }
    &__wrapper-2 {
        //display: block;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        overflow: hidden;
    }
    &__wrapper {
        padding: 4.7rem 2rem 3rem;
    }
}
