.home-events {
    margin: 0 0 7rem;

    &__wrap {
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 -1.8rem;
    }

    &__item {
        margin: 0 2.5rem 4.5rem;
        width: calc(50% - 5rem);

        &:last-child {
            margin-bottom: 4.5rem;
        }
    }

    .home-section_lg-button {
        margin: 0 auto;
        width: 34rem;
    }
}
