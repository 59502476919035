// @name Liste type 3
// @description Directory list
.list-type-3__listitems {
	.link-bloc__context {
		position: static;
		display: inherit;
	}
    .list-paginated__wrapper {
        flex-direction: column;
        margin: 0;
        display: block;
        > article {
            flex: 0 1 100%;
            max-width: inherit;
            margin: 0 0 3em; 
        }
    }
    .list-type-3__picture {
    	img {
    		width: 100%;
    	}
    }
    .list-infos {
    	&__wrapper {
    		display: block;
    		    .list-infos__listitems {
    		    	&:first-child {
    		    		flex: 0 1 100%;
    		    	}
    		    }
    	}
    }
}
