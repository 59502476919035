.mob-nav {
	.search-box {
		display: block;
		background: $color-black;
		padding: 2rem;
		margin: 0;
		float: none;
        height: 9.2rem;

		.ddm__sub-level {
			width: 100%;
			padding: 0 !important;
		}

		label {
			display: none;
		}

		&__fields-wrapper {
			display: block;
		}

		&__field-wrapper {
			width: 100%;
			border: 0;
			height: auto;
			padding: 0 5rem 0 0;

			.search-box__button {
				width: 5rem;
				height: 5rem;
				background: $color-2--2;
                top: 0;
                right: 0;

				&:hover,
                &:focus {
                    svg {
                        fill: $color-1--2;
                    }
                }
			}

			input {
				background: lighten($color-black, 24.71);
                border: 0;
				height: 5rem;
				color: $color-white;
				font-weight: 300;
			}
		}
	}
}
